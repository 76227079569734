import {
  Box,
  CloseButton,
  Container,
  Link,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import * as React from "react";

export default function Banner() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box
      bg="blackAlpha.900"
      boxShadow={useColorModeValue("sm", "sm-dark")}
      position={"fixed"}
      display={isOpen ? "none" : "block"}
      bottom={0}
      width={"100%"}
      zIndex={4}
      fontSize={"lg"}
    >
      <Container py={{ base: "4", md: "3.5" }}>
        <Stack
          direction={{ base: "column", md: "row" }}
          justify="center"
          spacing={{ base: "0.5", md: "1.5" }}
          pe={{ base: "4", sm: "0" }}
        >
          {/* <Text color="white">
            To visit site with old contract,{" "}
            <Link href="https://old.skyweaverswap.com">click here</Link>
          </Text> */}
        </Stack>
        <CloseButton
          position="absolute"
          right="2"
          top="2.5"
          color="white"
          onClick={onOpen}
        />
      </Container>
    </Box>
  );
}
