import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  VStack,
  RadioGroup,
  Radio,
} from "@chakra-ui/react";
import { useState } from "react";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";

type Props = {
  isOpen: any;
  onClose: any;
};

export default function LanguageModal({ isOpen, onClose }: Props) {
  const { t, i18n } = useTranslation("translation");
  const [value, setValue] = useState(i18n.resolvedLanguage);

  const handleChange = (value) => {
    localStorage.setItem("i18nextLng", value);
    setValue(value);
    i18n.changeLanguage(value);

    ReactGA.event({
      category: "Settings",
      action: "Change Language",
      label: value,
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="xs">
      <ModalOverlay />
      <ModalContent border="1px" borderStyle="solid" borderRadius="3xl">
        <ModalHeader color="black" px={4} fontSize="lg" fontWeight="bold">
          {t("languageModal.title")}
        </ModalHeader>
        <ModalCloseButton
          color="black"
          fontSize="sm"
          _hover={{
            color: "whiteAlpha.700",
          }}
        />
        <ModalBody pt={0} px={4}>
          <Box borderRadius="3xl" px={5} pt={4} pb={2} mb={3}>
            <VStack>
              <RadioGroup onChange={handleChange} value={value}>
                <VStack>
                  <Radio value="en">English</Radio>
                  <Radio value="zh">简体中文</Radio>
                </VStack>
              </RadioGroup>
            </VStack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
