import {
  Box,
  Link,
  VStack,
  Text,
  SimpleGrid,
  GridItem,
  Container,
  Input,
  HStack,
  Spacer,
  TabList,
  Tabs,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import React, { useState } from "react";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";

export default function DataHome() {
  const { t, i18n } = useTranslation("translation");
  const [days, setDays] = useState(120);
  ReactGA.pageview(window.location.pathname);

  const rankingUrls = [];
  const marketUrls = [];
  const liquidityUrls = [];
  const conquestUrls = [];

  if (i18n.resolvedLanguage == "zh") {
    // Gold holder ranking
    rankingUrls.push(
      `https://dune.com/embeds/710430/1309344/8500e39d-766c-4aed-941c-24041b8b8b48`
    );
    // Average card price
    marketUrls.push(
      `https://dune.xyz/embeds/533765/1004991/9cd57f90-43ee-43cc-94d5-92bdef0838e2?Days=${days}`
    );
    // Cost for 1 of each gold
    marketUrls.push(
      `https://dune.xyz/embeds/533758/1004973/9c680ef4-eaff-4a1a-b876-4a8394f9ea5d?Days=${days}`
    );
    // Monthly Trade Volumn USDC
    marketUrls.push(
      `https://dune.xyz/embeds/540059/1015634/97ad2d4b-e18a-48c9-ab16-4fb0513df03e`
    );
    // Trade Volumn USDC
    marketUrls.push(
      `https://dune.xyz/embeds/441271/839233/6a73ff7c-5914-4b4d-8afa-842a4dadb445?Days=${days}`
    );
    // Trade Volumn # of Trades
    marketUrls.push(
      `https://dune.xyz/embeds/441289/839265/a7ed8189-e9c2-4650-a884-3ba582531a45?Days=${days}`
    );
    // Marketplace DAU
    marketUrls.push(
      `https://dune.xyz/embeds/441334/839315/0a66882e-a59f-4d3c-9332-15ec85858a17?Days=${days}`
    );
    // Daily Conequest players
    conquestUrls.push(
      `https://dune.xyz/embeds/451201/857504/1a4baa71-03fe-44a4-947c-07b37d664288?Days=${days}`
    );
    // Liquidity movement USDC
    liquidityUrls.push(
      `https://dune.xyz/embeds/441341/839323/49e28bff-1507-4bfc-8f5b-f5ce329a339f?Days=${days}`
    );
    // Liquidity movement # of transactions
    liquidityUrls.push(
      `https://dune.xyz/embeds/441440/839428/e051c823-3af5-4102-a8df-12e21e53136d?Days=${days}`
    );
  } else {
    // Gold holder ranking
    rankingUrls.push(
      `https://dune.com/embeds/710425/1309338/7d60a036-aa3b-478b-86c7-0dbece26e7e4`
    );
    // Average card price
    marketUrls.push(
      `https://dune.xyz/embeds/528517/1004489/8ad83bdc-6a33-4f33-9920-9957b62e74e5?Days=${days}`
    );
    // Cost for 1 of each Gold
    marketUrls.push(
      `https://dune.xyz/embeds/533752/1004959/a5774530-c65c-44e3-8475-c4727f6b4874?Days=${days}`
    );
    // Monthly Trade Volumn USDC
    marketUrls.push(
      `https://dune.xyz/embeds/540055/1015623/8b79c95e-e2d5-4531-b568-c5e118c9e0d4`
    );
    // Trade Volumn USDC
    marketUrls.push(
      `https://dune.xyz/embeds/388635/740902/948a3ecd-f140-4393-a37a-818a34058d75?Days=${days}`
    );
    // Trade Volumn # of Trades
    marketUrls.push(
      `https://dune.xyz/embeds/388924/741481/d18294ff-ce99-4cd9-868a-9c76d84fed10?Days=${days}`
    );
    // Marketplace DAU
    marketUrls.push(
      `https://dune.xyz/embeds/413202/788335/e1bef37d-4b23-42df-b8e1-49608bc6124b?Days=${days}`
    );
    // Daily Conequest players
    conquestUrls.push(
      `https://dune.xyz/embeds/448696/852788/5b44e242-6375-40b4-8472-37a228a880a8?Days=${days}`
    );
    // Liquidity movement USDC
    liquidityUrls.push(
      `https://dune.xyz/embeds/388972/741558/b88b14d5-828f-4a55-9100-754015bc3b72?Days=${days}`
    );
    // Liquidity movement # of transactions
    liquidityUrls.push(
      `https://dune.xyz/embeds/388987/741586/69a810b6-493e-4f95-a39b-e090a24f3816?Days=${days}`
    );
  }

  const gridItemsWithUrls = (urls) => {
    return (
      <SimpleGrid columns={{ base: 1, xl: 2 }} spacing={2} w={"100%"}>
        {urls.map(function (object, i) {
          return (
            <GridItem>
              <Box as="iframe" h="2xl" w="100%" src={object}></Box>
            </GridItem>
          );
        })}
      </SimpleGrid>
    );
  };

  const inputSection = (
    <Container paddingTop={"5px"}>
      <HStack>
        <Spacer />
        <Text>{t("translation:data.showDataForDays-1")}</Text>
        <Input
          w={"70px"}
          type="number"
          value={days}
          onChange={(e) => setDays(parseInt(e.target.value))}
        />
        <Text>{t("translation:data.showDataForDays-2")}</Text>
        <Spacer />
      </HStack>
    </Container>
  );

  return (
    <React.Fragment>
      <VStack bg="white">
        {inputSection}
        <HStack paddingTop={"5px"} w="100%">
          <Tabs variant="enclosed" w="100%" px="10px">
            <TabList>
              <Tab>{t("translation:data.market")}</Tab>
              <Tab>{t("translation:data.ranking")}</Tab>
              <Tab>{t("translation:data.conquest")}</Tab>
              <Tab>{t("translation:data.liquidity")}</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>{gridItemsWithUrls(marketUrls)}</TabPanel>
              <TabPanel>{gridItemsWithUrls(rankingUrls)}</TabPanel>
              <TabPanel>{gridItemsWithUrls(conquestUrls)}</TabPanel>
              <TabPanel>{gridItemsWithUrls(liquidityUrls)}</TabPanel>
            </TabPanels>
          </Tabs>
          <Spacer />
        </HStack>
        <Text>
          {t("translation:data.forkedFrom")}{" "}
          <Link color="blue" href="https://dune.xyz/phabc/Skyweaver">
            PhABC
          </Link>
        </Text>
      </VStack>
    </React.Fragment>
  );
}
