import {
  Button,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import styles from "../../css/web3.module.css";
import { useGlobalState } from "../../hooks/GlobalState";
const MENU_WIDTH = "150px";
export function BulkButton({ tradingMode, handleBulkModeChange }) {
  const { t, i18n } = useTranslation("translation");
  const [globalState, dispatch] = useGlobalState();
  return tradingMode === "normal" ? (
    <Menu>
      <MenuButton
        as={Button}
        height="33px"
        width="110px"
        fontSize={"sm"}
        className={[
          styles.button,
          styles.buttonGradientBlue,
          styles.buttonGlow,
          styles.ultraThinButton,
        ].join(" ")}
      >
        {t("translation:market.bulk.bulkAction")}
      </MenuButton>
      <MenuList width={MENU_WIDTH}>
        <MenuGroup title={t("translation:market.action.trade")}>
          {globalState.needApproval ? null : (
            <MenuItem
              onClick={() => {
                handleBulkModeChange("bulkBuy");
              }}
            >
              {t("translation:market.action.buy")}
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              handleBulkModeChange("bulkSell");
            }}
          >
            {t("translation:market.action.sell")}
          </MenuItem>
        </MenuGroup>
        {globalState.needApproval ? null : (
          <MenuGroup title={t("translation:market.action.liquidity")}>
            <MenuItem
              onClick={() => {
                handleBulkModeChange("bulkAddLiquidity");
              }}
            >
              {t("translation:market.action.addLiquidity")}
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleBulkModeChange("bulkRemoveLiquidity");
              }}
            >
              {t("translation:market.action.withdrawLiquidity")}
            </MenuItem>
          </MenuGroup>
        )}
      </MenuList>
    </Menu>
  ) : (
    <Button
      height="33px"
      width="120px"
      fontSize={"sm"}
      className={[
        styles.button,
        styles.buttonGradientBlue,
        styles.buttonGlow,
        styles.ultraThinButton,
      ].join(" ")}
      onClick={() => {
        const newCart = new Map();
        dispatch({ shoppingCart: newCart });
        handleBulkModeChange("normal");
      }}
    >
      {t("translation:market.bulk.exitBulkMode")}
    </Button>
  );
}
