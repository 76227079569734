import {
  Box,
  Button,
  Flex,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  HStack,
  Spacer,
} from "@chakra-ui/react";
import ReactGA from "react-ga";
import ConnectButton from "../ConnectButton";

type Props = {
  isOpen: any;
  onClose: any;
};

export default function ConnectWalletModal({ isOpen, onClose }: Props) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="2xl">
      <ModalOverlay />
      <ModalContent
        background="gray.900"
        border="1px"
        borderStyle="solid"
        borderColor="gray.700"
        borderRadius="3xl"
      >
        <ModalHeader
          color="white"
          px={4}
          py={10}
          fontSize="lg"
          fontWeight="medium"
          textAlign={"center"}
        >
          Connect your wallet to trade and manage liquidity
        </ModalHeader>
        <ModalCloseButton
          color="white"
          fontSize="sm"
          _hover={{
            color: "whiteAlpha.700",
          }}
        />
        <ModalBody pt={0} px={4} py={8}>
          <HStack>
            <Spacer />
            <ConnectButton showOther={false} connectCallback={onClose} />
            <Spacer />
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
