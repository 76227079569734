import {
  GridItem,
  Button,
  Box,
  Text,
  VStack,
  HStack,
  AspectRatio,
  Image,
  Spacer,
  useDisclosure,
  Divider,
  IconButton,
  Tooltip,
  Tag,
  Link,
  Container,
} from "@chakra-ui/react";
import ConfirmTradeModal from "./ConfirmTradeModal";
import ManageAssetModal from "./ManageAssetModal";
import ManageLiquidityModal from "./ManageLiquidityModal";
import { FiStar } from "react-icons/fi";
import React, { useEffect } from "react";
import CardDataModal from "./CardDataModal";
import ReactGA from "react-ga";
import styles from "../../css/web3.module.css";
import { useTranslation } from "react-i18next";
import { QuantityPicker } from "./Cart/QuantityPicker";
import { useGlobalState } from "../../hooks/GlobalState";
import Decimal from "decimal.js";
import ConnectWalletModal from "./ConnectWalletModal";

type Props = {
  card: any;
  textColor: string;
  showLiquidityDetail: boolean;
  mode: string;
  refreshCallback: any;
  star?: boolean;
  onStarCallback?: any;
  enableStar?: boolean;
};

const BUY_COLOR = "#BCEE7C";
const SELL_COLOR = "#EE7C7C";

export default function CardV2({
  card,
  textColor,
  showLiquidityDetail,
  mode,
  refreshCallback,
  star,
  onStarCallback,
  enableStar = false,
}: Props) {
  const [globalState, dispatch] = useGlobalState();
  const { t, i18n } = useTranslation("translation");
  const [isStarred, setIsStarred] = React.useState(false);
  const {
    isOpen: isTradeOpen,
    onOpen: onTradeOpen,
    onClose: onTradeClose,
  } = useDisclosure();
  const {
    isOpen: isLiquidityOpen,
    onOpen: onLiquidityOpen,
    onClose: onLiquidityClose,
  } = useDisclosure();
  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();
  const {
    isOpen: isDataOpen,
    onOpen: onDataOpen,
    onClose: onDataClose,
  } = useDisclosure();
  const {
    isOpen: isConnectOpen,
    onOpen: onConnectOpen,
    onClose: onConnectClose,
  } = useDisclosure();
  const successCallback = () => {
    onConfirmClose();
    refreshCallback();
  };
  const closeModal = (showConfirm: boolean) => {
    onTradeClose();
    onLiquidityClose();
    if (showConfirm) {
      onConfirmOpen();
    }
  };

  const openDataModal = () => {
    onDataOpen();
    ReactGA.event({
      category: "Activity",
      action: "Open card data",
      label: card["rarity"],
      dimension1: card["name"],
    });
  };

  const openTradeModal = () => {
    if (globalState.account) {
      onTradeOpen();
      ReactGA.event({
        category: "Activity",
        action: "Open card trade",
        label: card["rarity"],
        dimension1: card["name"],
      });
    } else {
      onConnectOpen();
      ReactGA.event({
        category: "Activity",
        action: "Open connect wallet",
        label: card["rarity"],
        dimension1: card["name"],
      });
    }
  };

  const openLiquidityModal = () => {
    if (globalState.account) {
      onLiquidityOpen();
      ReactGA.event({
        category: "Activity",
        action: "Open card liquidity",
        label: card["rarity"],
        dimension1: card["name"],
      });
    } else {
      onConnectOpen();
      ReactGA.event({
        category: "Activity",
        action: "Open connect wallet",
        label: card["rarity"],
        dimension1: card["name"],
      });
    }
  };

  let max;
  switch (mode) {
    case "bulkBuy":
      max = Math.floor(card["pool_cardInPool"] - 0.01);
      break;
    case "bulkSell":
      max = card["self_cardOwned"];
      break;
    case "bulkAddLiquidity":
      max = card["self_cardOwned"];
      break;
    case "bulkRemoveLiquidity":
      max = card["pool_ownedCardInPool"];
      break;
  }

  useEffect(() => {
    setIsStarred(star);
  }, [star]);

  const onValueChange = (newValue: number) => {
    var newCart = new Map(globalState.shoppingCart);
    let newCardItem = newCart.get(card["ID"]);
    if (!newCardItem) {
      newCardItem = {
        card: card,
        count: newValue,
      };
    } else {
      newCardItem["count"] = newValue;
    }
    if (newValue > 0) {
      newCart.set(card["ID"], newCardItem);
    } else {
      newCart.delete(card["ID"]);
    }
    dispatch({ shoppingCart: newCart });
  };

  const bulkActionSection = (
    <VStack spacing={3} align={"left"}>
      <QuantityPicker
        min={0}
        initValue={0}
        max={max}
        width={"180px"}
        onValueChange={onValueChange}
        color={textColor}
        card_id={card["ID"]}
        autoUpdate={true}
        maxButtonPositon="bottom"
      ></QuantityPicker>
    </VStack>
  );

  const normalActionSection = (
    <VStack spacing={3} align={"left"}>
      <button
        className={[
          styles.fullButton,
          styles.button,
          styles.buttonGradientBlue,
          styles.buttonGlow,
          styles.thinButton,
        ].join(" ")}
        onClick={openTradeModal}
      >
        {t("translation:market.action.trade")}
      </button>
      <button
        className={[
          styles.fullButton,
          styles.button,
          styles.buttonGradientBorderBlue,
          styles.buttonGlow,
          styles.thinButton,
        ].join(" ")}
        onClick={openLiquidityModal}
      >
        {t("translation:market.action.liquidity")}
      </button>
      {/* {card["rarity"] === "crystal" ? null : (
        <Link
          color={textColor}
          textAlign="center"
          fontWeight="medium"
          fontSize="md"
          textDecoration={"underline"}
          onClick={openDataModal}
        >
          {t("translation:market.card.seeMoreData")}
        </Link>
      )} */}
    </VStack>
  );

  let actionSection;
  if (mode.startsWith("bulk")) {
    actionSection = bulkActionSection;
  } else {
    actionSection = normalActionSection;
  }

  let cardDetailSection = (
    <VStack paddingTop={"15px"} spacing={3} align={"left"} minW={"160px"}>
      <VStack spacing={3} align={"left"}>
        <HStack>
          <VStack>
            <Box className={styles.dot}></Box>
            <Spacer />
            <Spacer />
            <Spacer />
          </VStack>
          <VStack align={"left"}>
            <Text className={styles.cardPropertyTitle}>
              {t("translation:market.card.totalAssetValue")}:
            </Text>
            <HStack>
              <Image src={"/icon/usdc.png"} className={styles.cardUSDCIcon} />
              <Text className={styles.cardPropertyDetail}>
                {(
                  card["self_ownedCardValue"] +
                  card["pool_ownedCashValue"] +
                  card["pool_ownedCardValue"]
                ).toFixed(2)}
              </Text>
            </HStack>
          </VStack>
        </HStack>
      </VStack>
      <Divider borderColor="rgba(255,255,255,0.2)" />
      <HStack>
        <VStack>
          <Box className={styles.dot}></Box>
          <Spacer />
          <Spacer />
          <Spacer />
        </VStack>
        <VStack align={"left"}>
          <Text className={styles.cardPropertyTitle}>
            {card["rarity"] === "crystal"
              ? t("translation:market.card.crystalOwned")
              : t("translation:market.card.cardOwned")}
            :
          </Text>
          <HStack>
            <Text className={styles.cardPropertyDetail}>
              {card["self_cardOwned"]} (
            </Text>
            <Image src={"/icon/usdc.png"} className={styles.cardUSDCIcon} />
            <Text className={styles.cardPropertyDetail}>
              {card["self_ownedCardValue"].toFixed(2)}
            </Text>
            <Text className={styles.cardPropertyDetail}>)</Text>
          </HStack>
        </VStack>
      </HStack>
      <Divider borderColor="rgba(255,255,255,0.2)" />
      <VStack spacing={3} align={"left"}>
        <HStack>
          <VStack>
            <Box className={styles.dot}></Box>
            <Spacer />
            <Spacer />
            <Spacer />
          </VStack>
          <VStack align={"left"}>
            <Text className={styles.cardPropertyTitle}>
              {t("translation:market.card.ownedCardInPoolV2")}:
            </Text>
            <HStack>
              <Text className={styles.cardPropertyDetail}>
                {card["pool_ownedCardInPool"]}
              </Text>
              <Text className={styles.cardPropertyDetail}>/</Text>
              <Image src={"/icon/usdc.png"} className={styles.cardUSDCIcon} />
              <Text className={styles.cardPropertyDetail}>
                {card["pool_ownedCashValue"].toFixed(2)}
              </Text>
            </HStack>
          </VStack>
        </HStack>
      </VStack>
      <Divider borderColor="rgba(255,255,255,0.2)" />
      {card["supply"] > 0 || card["name"] === "Conquest Ticket" ? (
        <VStack spacing={3} align={"left"}>
          <HStack>
            <VStack>
              <Box className={styles.dot}></Box>
              <Spacer />
              <Spacer />
              <Spacer />
            </VStack>
            <VStack align={"left"}>
              <Text className={styles.cardPropertyTitle}>
                {t("translation:market.card.stock")} /{" "}
                {t("translation:market.card.supply")}:
              </Text>
              <Text className={styles.cardPropertyDetail}>
                {card["pool_cardInPool"]} /{" "}
                {card["name"] === "Conquest Ticket"
                  ? "∞"
                  : card["supply"].toLocaleString("en-US")}
              </Text>
            </VStack>
          </HStack>
        </VStack>
      ) : null}
      {card["supply"] > 0 || card["name"] === "Conquest Ticket" ? (
        <Divider borderColor="rgba(255,255,255,0.2)" />
      ) : null}
      <VStack spacing={3} align={"left"}>
        <HStack>
          <VStack>
            <Box className={styles.dot}></Box>
            <Spacer />
            <Spacer />
            <Spacer />
          </VStack>
          <VStack align={"left"}>
            <Text className={styles.cardPropertyTitle}>
              {t("translation:market.card.poolOwnership")}:
            </Text>
            <Text className={styles.cardPropertyDetail}>
              {Math.round(card["pool_ownedPercentage"] * 10000) / 100}%
            </Text>
          </VStack>
        </HStack>
      </VStack>
      <Divider borderColor="rgba(255,255,255,0.2)" />
      <Spacer />
      {actionSection}
    </VStack>
  );

  let cardDetailSectionWithoutLiquidity = (
    <VStack paddingTop={"15px"} spacing={3} align={"left"} minW={"160px"}>
      <HStack>
        <VStack>
          <Box className={styles.dot}></Box>
          <Spacer />
          <Spacer />
          <Spacer />
        </VStack>
        <VStack align={"left"}>
          <Text className={styles.cardPropertyTitle}>
            {card["rarity"] === "crystal"
              ? t("translation:market.card.crystalOwned")
              : t("translation:market.card.cardOwned")}
            :
          </Text>
          <HStack>
            <Text className={styles.cardPropertyDetail}>
              {card["self_cardOwned"]} (
            </Text>
            <Image src={"/icon/usdc.png"} className={styles.cardUSDCIcon} />
            <Text className={styles.cardPropertyDetail}>
              {card["self_ownedCardValue"].toFixed(2)}
            </Text>
            <Text className={styles.cardPropertyDetail}>)</Text>
          </HStack>
        </VStack>
      </HStack>
      <Divider borderColor="rgba(255,255,255,0.2)" />
      {card["supply"] > 0 ? (
        <VStack spacing={3} align={"left"}>
          <HStack>
            <VStack>
              <Box className={styles.dot}></Box>
              <Spacer />
              <Spacer />
              <Spacer />
            </VStack>
            <VStack align={"left"}>
              <Text className={styles.cardPropertyTitle}>
                {t("translation:market.card.supply")}:
              </Text>
              <Text className={styles.cardPropertyDetail}>
                {card["supply"].toLocaleString("en-US")}
              </Text>
            </VStack>
          </HStack>
        </VStack>
      ) : null}
      {card["supply"] > 0 ? (
        <Divider borderColor="rgba(255,255,255,0.2)" />
      ) : null}
      <VStack spacing={3} align={"left"}>
        <HStack>
          <VStack>
            <Box className={styles.dot}></Box>
            <Spacer />
            <Spacer />
            <Spacer />
          </VStack>
          <VStack align={"left"}>
            <Text className={styles.cardPropertyTitle}>
              {t("translation:market.card.stock")}:
            </Text>
            <Text className={styles.cardPropertyDetail}>
              {card["pool_cardInPool"]}
            </Text>
          </VStack>
        </HStack>
      </VStack>
      <Divider borderColor="rgba(255,255,255,0.2)" />
      <Spacer />
      <Spacer />
      {actionSection}
    </VStack>
  );

  let cardDetailSectionWithoutAccount = (
    <VStack spacing={3} align={"left"} minW={"160px"}>
      <Spacer />
      <Spacer />
      {card["supply"] > 0 ? (
        <VStack spacing={3} align={"left"}>
          <HStack>
            <VStack>
              <Box className={styles.dot}></Box>
              <Spacer />
              <Spacer />
              <Spacer />
            </VStack>
            <VStack align={"left"}>
              <Text className={styles.cardPropertyTitle}>
                {t("translation:market.card.supply")}:
              </Text>
              <Text className={styles.cardPropertyDetail}>
                {card["supply"].toLocaleString("en-US")}
              </Text>
            </VStack>
          </HStack>
        </VStack>
      ) : null}
      {card["supply"] > 0 ? (
        <Divider borderColor="rgba(255,255,255,0.2)" />
      ) : null}
      <VStack spacing={3} align={"left"}>
        <HStack>
          <VStack>
            <Box className={styles.dot}></Box>
            <Spacer />
            <Spacer />
            <Spacer />
          </VStack>
          <VStack align={"left"}>
            <Text className={styles.cardPropertyTitle}>
              {t("translation:market.card.stock")}:
            </Text>
            <Text className={styles.cardPropertyDetail}>
              {card["pool_cardInPool"]}
            </Text>
          </VStack>
        </HStack>
      </VStack>
      <Divider borderColor="rgba(255,255,255,0.2)" />
      <Spacer />
      <Spacer />
      {actionSection}
    </VStack>
  );

  return (
    <GridItem borderRadius="2xl" className={styles.cardItem}>
      <VStack position="relative">
        {enableStar ? (
          <IconButton
            display={{ base: "none", md: "flex" }}
            variant="unstyled"
            aria-label="Star"
            icon={
              <FiStar
                color={isStarred ? "yellow" : "white"}
                size={"25px"}
                strokeWidth={"1px"}
                fill={isStarred ? "yellow" : ""}
              />
            }
            position={"absolute"}
            right={"0px"}
            top={"5px"}
            isRound={true}
            onClick={() => {
              ReactGA.event({
                category: "Filter",
                action: "Star Card",
                label: card["name"],
              });
              setIsStarred(!isStarred);
              onStarCallback(card["ID"], !isStarred);
            }}
          ></IconButton>
        ) : null}
        <HStack alignItems="flex-start" spacing={3}>
          <VStack spacing={2} align={"center"}>
            <Box w={"135px"}>
              <AspectRatio ratio={0.65} w={"129px"}>
                <Image
                  paddingRight={"6px"}
                  src={card["Image_URL"]}
                  alt="Card"
                />
              </AspectRatio>
            </Box>
            <Text
              justifyContent="flex-start"
              color={textColor}
              textAlign="left"
              fontWeight={"bold"}
              fontSize="lg"
              paddingBottom={"6px"}
            >
              {card["name"]}
            </Text>
            {mode !== "bulkBuy" ? (
              <Box paddingTop={"3px"} alignContent={"center"}>
                <Text
                  color={SELL_COLOR}
                  textAlign="center"
                  fontWeight="medium"
                  fontSize={"14px"}
                  lineHeight={"7px"}
                  paddingBottom={"3px"}
                >
                  {t("translation:market.card.sellPriceV2")}:
                </Text>
                <HStack>
                  <Spacer />
                  {card["rarity"] === "crystal" ? null : (
                    <Text
                      textAlign="center"
                      color={SELL_COLOR}
                      textDecoration={"line-through"}
                    >
                      {card["card_sellPrice"]}
                    </Text>
                  )}
                  <Text
                    textAlign="center"
                    color={SELL_COLOR}
                    fontWeight="bold"
                    fontSize={"16px"}
                  >
                    {parseFloat(card["card_sksSellPrice"]).toFixed(2)}
                  </Text>
                  <Spacer />
                </HStack>
              </Box>
            ) : null}
            {mode !== "bulkSell" ? (
              <Box paddingTop={"3px"} alignContent={"center"}>
                <Text
                  color={BUY_COLOR}
                  textAlign="center"
                  fontWeight="medium"
                  fontSize={"14px"}
                  lineHeight={"7px"}
                  paddingBottom={"3px"}
                >
                  {t("translation:market.card.buyPriceV2")}:
                </Text>
                <HStack>
                  <Spacer />
                  {card["rarity"] === "crystal" ? null : (
                    <Text color={BUY_COLOR} textDecoration={"line-through"}>
                      {card["card_buyPrice"]}
                    </Text>
                  )}
                  <Text
                    textAlign="center"
                    color={BUY_COLOR}
                    fontWeight="bold"
                    fontSize={"16px"}
                  >
                    {parseFloat(card["card_sksBuyPrice"]).toFixed(2)}
                  </Text>
                  <Spacer />
                </HStack>
              </Box>
            ) : null}
          </VStack>
          {globalState.account
            ? showLiquidityDetail
              ? cardDetailSection
              : cardDetailSectionWithoutLiquidity
            : cardDetailSectionWithoutAccount}
        </HStack>
        {globalState.account ? (
          <Box>
            <ManageAssetModal
              isOpen={isTradeOpen}
              onClose={closeModal}
              card={card}
              succeedCallback={successCallback}
            />
            <ManageLiquidityModal
              isOpen={isLiquidityOpen}
              onClose={closeModal}
              card={card}
              successCallback={successCallback}
            />
            <ConfirmTradeModal
              isOpen={isConfirmOpen}
              onClose={onConfirmClose}
            />
          </Box>
        ) : (
          <ConnectWalletModal isOpen={isConnectOpen} onClose={onConnectClose} />
        )}
        <CardDataModal isOpen={isDataOpen} onClose={onDataClose} card={card} />
      </VStack>
    </GridItem>
  );
}
