import {
  Container,
  Stack,
  Box,
  Heading,
  Text,
  AspectRatio,
  Image,
  Center,
  Link,
} from "@chakra-ui/react";
import React from "react";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";

export default function LiquidityManagementGuide() {
  const { t, i18n } = useTranslation("translation");
  ReactGA.pageview(window.location.pathname);

  if (i18n.resolvedLanguage === "zh") {
    return (
      <React.Fragment>
        <Container maxW={"3xl"} textColor={"white"}>
          <Stack
            as={Box}
            textAlign={"center"}
            spacing={{ base: 8, md: 10 }}
            py={{ base: 20, md: 36 }}
          >
            <Heading fontWeight={100} fontSize={"4xl"} lineHeight={"110%"}>
              <b>{t("translation:guide.index.howToLiquidityTitle")}</b>
              <br />
            </Heading>
            <Text margin-top={"0"} color={"black.500"} textAlign={"left"}>
              如果您不知道什么是流动性，请先查看
              <Link textDecoration={"underline"} href="/Guide/LiquidityGuide">
                {t("translation:guide.index.liquidityTitle")}
              </Link>
              一文。
              <br />
              <br />
              要为卡池提供流动性，<b>您需要卡牌本身和相应的 USDC</b>。
              比如下面的 Pharonis，如果你想在流动性池中添加 1
              张，你还需要在流动性池中投入 57.72 USDC（池内单卡价格）。
              这是为了确保添加/赎回流动性不会影响卡的价格。
              <br />
              <br />
              <Center>
                <AspectRatio ratio={0.6} w={"300px"} textAlign={"center"}>
                  <Image src={"/img/zh/liquidity-example.png"} alt="Card" />
                </AspectRatio>
              </Center>
              <br />
              <br />
              一旦您将流动性添加到池中，您将拥有该流动池的一定百分比所有权，如果没有其他人添加/赎回流动性，此值不会改变。
              随着池中 USDC 和卡牌数量的变化，池内卡牌数量/池内USDC数量
              也会相应变化。
              <br />
              <br />
              您可能会注意到的一件事是，在上面的屏幕截图中，它显示您在流动池中拥有
              1.03 张卡牌。 这并不是显示错误，卡牌张数的最小单位为 0.01 。这里的
              0.03 张卡你可以在 SkyweaverSwap 中看到它并出售它！ 或者在
              SkyweaverSwap 中购买 0.97 张卡将其补全为一张完整的卡牌！
              交易部分请阅读
              <Link href="/Guide/TradeGuide" textDecoration={"underline"}>
                {t("translation:guide.index.howToTradeTitle")}
              </Link>
              。
              <br />
              <br />在 SkyweaverSwap 中添加/赎回流动性非常简单。
              进入市场页面，点击您要修改的卡片上的<b>流动池</b>按钮。
              <br />
              <br />
              <Center>
                <AspectRatio ratio={0.65} w={"300px"} textAlign={"center"}>
                  <Image src={"/img/zh/liquidity-card.png"} alt="Card" />
                </AspectRatio>
              </Center>
              <br />
              <br />
              第一步是批准 Skyweaver 访问您的 USDC。
              这是因为当您发送请求时，智能合约不能确定流动性所需的 USDC 数量。
              为此，只需单击流动性弹出窗口中的批准按钮，然后在您的钱包中确认。
              <br />
              <br />
              <Center>
                <AspectRatio ratio={1.08} w={"500px"} textAlign={"center"}>
                  <Image src={"/img/zh/approve-button.png"} />
                </AspectRatio>
              </Center>
              <br />
              <br />
              您应该会看到如下所示的详细信息页面。
              输入要操作的卡牌数量（最小单位为 0.01），然后单击添加/赎回按钮。
              您可以看到为您输入的卡牌添加流动性所需的对应的 USDC
              数量（质押数量对应USDC）。
              <br />
              <br />
              <Center>
                <AspectRatio ratio={1.08} w={"600px"} textAlign={"center"}>
                  <Image src={"/img/zh/liquidity-detail.png"} alt="Card" />
                </AspectRatio>
              </Center>
              <br />
              <br />
              添加/赎回流动性后，您还可以前往游戏内市场通过查看对应的卡牌存货(Stock)进行验证。
              它会随着您添加/赎回流动性而改变。
            </Text>
          </Stack>
        </Container>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <Container maxW={"3xl"} textColor={"white"}>
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 36 }}
        >
          <Heading fontWeight={100} fontSize={"4xl"} lineHeight={"110%"}>
            <b>How can I provide liquidity?</b>
            <br />
          </Heading>
          <Text margin-top={"0"} color={"black.500"} textAlign={"left"}>
            If you don't know what liquidity is about, take a look at the
            article{" "}
            <Link textDecoration={"underline"} href="/Guide/LiquidityGuide">
              Liquidity in Skyweaver marketplace
            </Link>{" "}
            first.
            <br />
            <br />
            The first step is to approve Skyweaver to access to your USDC. This
            is because the amount of USDC needed for liqudity is unknown to the
            smart contract when you send the request. To do it, just click on
            the button in the liquidity pop up, and confirm it in your wallet.
            <br />
            <br />
            <Center>
              <AspectRatio ratio={6} w={"500px"} textAlign={"center"}>
                <Image src={"/img/approve-button.png"} />
              </AspectRatio>
            </Center>
            <br />
            <br />
            To provide liquidity to a card pool, you need{" "}
            <b>both the card itself and the according USDC</b>. For example, for
            the Epic Eagle card below, if you want to add <b>1 copy</b> of it to
            the liquidity pool, you also need to put{" "}
            <b>31.09 USDC(Card liquidity value)</b> in the pool as well. This is
            to make sure adding/removing liquidity won't affect the price of the
            card.
            <br />
            <br />
            <Center>
              <AspectRatio ratio={0.7} w={"500px"} textAlign={"center"}>
                <Image src={"/img/liquidity-example.png"} alt="Card" />
              </AspectRatio>
            </Center>
            <br />
            <br />
            Once you have added liquidity to the pool, you will own a percentage
            of the total pool(<b>Pool ownership</b>), this value won't change if
            no one else adds/removes liquidity. As the USDC and cards in the
            pool change, how many cards(<b>Owned cards in Pool</b>)/how much
            USDC(<b>Owned cash Value</b>) you own in the pool will also change
            accordingly.
            <br />
            <br />
            One thing you may notice here is, in the screenshot above, it shows
            you own 2.62 cards. That is not an error, because you are owning a
            fixed percentage of the pool, this is expected. Don't worry about
            the 0.62 card here, once you withdraw it from the pool, you will be
            able to see it and sell it in SkyweaverSwap! Or buy 0.38 card in
            SkyweaverSwap to make it a full card! Check out how to do that in
            the guide{" "}
            <Link href="/Guide/TradeGuide" textDecoration={"underline"}>
              How to trade on SkyweaverSwap
            </Link>
            !
            <br />
            <br />
            It's very simple to provide/withdraw liquidity in SkyweaverSwap. Go
            to the Market page, click on the <b>Liquidity</b> button on the card
            you want to modify.
            <br />
            <br />
            <Center>
              <AspectRatio ratio={0.75} w={"500px"} textAlign={"center"}>
                <Image src={"/img/liquidity-card.png"} alt="Card" />
              </AspectRatio>
            </Center>
            <br />
            <br />
            Now you should see a detail page like below. Enter the number of
            cards(can be as little as 0.01) you want to add/remove and click the
            button. You can see the USDC you needed to add liqudity for the
            amount you enter(<b>USDC for the liquidity</b>).
            <br />
            <br />
            <Center>
              <AspectRatio ratio={1.02} w={"600px"} textAlign={"center"}>
                <Image src={"/img/liquidity-detail.png"} alt="Card" />
              </AspectRatio>
            </Center>
            <br />
            <br />
            Once you have add/remove liquidity, you can also go to the in-game
            market to verify it by looking at the card stock number there. It
            will change as you add/remove liquidity.
          </Text>
        </Stack>
      </Container>
    </React.Fragment>
  );
}
