import {
  Container,
  Stack,
  Box,
  Heading,
  Text,
  AspectRatio,
  Image,
  Center,
  Link,
} from "@chakra-ui/react";
import React from "react";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";

export default function ImpermanentLossGuide() {
  ReactGA.pageview(window.location.pathname);
  const { t, i18n } = useTranslation("translation");

  if (i18n.resolvedLanguage === "zh") {
    return (
      <React.Fragment>
        <Container maxW={"3xl"} textColor={"white"}>
          <Stack
            as={Box}
            textAlign={"center"}
            spacing={{ base: 8, md: 10 }}
            py={{ base: 20, md: 36 }}
          >
            <Heading fontWeight={100} fontSize={"4xl"} lineHeight={"110%"}>
              <b>{t("translation:guide.index.impermanentLossTitle")}</b>
              <br />
            </Heading>
            <Text fontWeight={100} fontSize={"2xl"} textAlign={"left"}>
              <b>什么是无常损失？</b>
            </Text>
            <Text margin-top={"0"} color={"black.500"} textAlign={"left"}>
              当您向流动性池提供流动性时，就会发生无常损失，并且与您存入资产时相比，
              您存入资产的价格会发生变化。这种变化越大，您面临的无常损失就越多。在这种情况下，
              损失意味着取款时的 USDC 价值与您仅持有它而不增加流动资金池相比。
              <br />
              <br />
              假设您将 10 张卡，100 USDC 添加到有 10 张卡，100 USDC
              的流动池中。现在你拥有池子的 50%，池子现在有 20 张卡，200
              美元兑美元。您的总美元价值为 200 USDC（每张卡 10
              USDC）。当您想退出时，可能会发生 4 种情况：
              <br />
              <br />
              <b>
                1) 市场不景气，卖卡的人比买卡的多，池子里有 40 张卡，120 美元。
                如果您现在赎回流动性，您将获得 20 张卡和 60 USDC。
              </b>
              <br />
              <br />
              您现在的总 USDC 价值是 60 + 20 * 3（现在价值 3 USDC 的卡）= 120
              USDC。 如果您保持持有而不是添加流动性，您现在将拥有 100 + 10 * 3 =
              130 USDC。 这里的 10 USDC 是你的无常损失。
              <br />
              <br />
              <b>
                2) 市场非常火热，买卡的为人比卖卡的多，池子内现在有 10 张卡和
                500 USDC。 如果您现在赎回流动性，您将获得 5 张卡和 250 USDC。
              </b>
              <br />
              <br />
              您现在的总美元价值是 250 + 5 * 50 = 500 USDC。
              如果您保持持有而不是添加流动性，您现在将拥有 100 + 10 * 50 = 600
              USDC。 这里的 100 USDC 是你的无常损失。
              <br />
              <br />
              <b>
                3）如果交易数量比较平稳，价格没有太大波动，那么一段时间后池中可能有
                20 张卡和 300 USDC，您赎回的将是 10 张卡和 150 USDC。
              </b>
              <br />
              <br />
              你现在的总美元价值是 150 + 10 * 15 = 300 USDC。
              如果您保持持有而不是添加流动性，您现在将拥有 100 + 10 * 15 = 250
              USDC。 您没有任何无常损失，并且有 50 USDC 的利润。
              <br />
              <br />
              <b>
                4）如果没有人交易，你的份额不会改变，此时赎回可以拿回 10 张卡和
                100 USDC。
                在这种情况下没有收益没有损失。（忽略数据精度问题，详情见
                <Link textDecoration={"underline"} href="/Guide/FAQ">
                  FAQ
                </Link>{" "}
                ）
              </b>
              <br />
              <br />
              这个例子向您展示了传统 AMM（自动做市商）中的无常损失。
              <br />
              <br />
              <Text fontWeight={100} fontSize={"2xl"} textAlign={"left"}>
                <b>Skyweaver 中的无常损失有什么特别之处？</b>
              </Text>
              <br />
              与其他加密货币相比，Skyweaver 的流动池有两点特别之处。
              <br />
              <br />
              <b>1) 与其他加密货币市场相比，流动池本身也太小了。</b>
              <br />
              <br />
              <b>2) 价格完全由唯一一个流动资金池——官方市场控制。</b>
              <br />
              <br />
              这两件事加在一起，意味着您不能简单地根据价格本身计算您的卡片价值，特别是当您拥有超过
              1 张卡片时。
              <br />
              假设您拥有 5 张卡，当前售价为 10
              USDC/张，不代表您的实际总资产价值是 50 USDC。
              当您将其出售给流动池时，您获得的总价格会更低，具体数量将取决于当时流动池中有多少张卡和
              USDC。
              <br />
              <br />
              让我们看一个来自市场的真实例子。 假设您拥有 5 张
              Mootici，现在它的售价是 <b>235.70 USDC</b>。
              如果你全部卖掉，纸面价值将是 <b>235.70 * 5 = 1178.5 USDC</b>。
              但实际总售价为 <b>821.19 USDC</b>。 每张卡实际售价约为{" "}
              <b>164.24 USDC</b>。
              <br />
              <br />
              <Center>
                <AspectRatio ratio={1.65} w={"700px"} textAlign={"center"}>
                  <Image src={"/img/zh/mootichi-price.png"} alt="Card" />
                </AspectRatio>
              </Center>
              <br />
              <br />
              如果您拥有 10 张并全部出售，则纸面价值将为{" "}
              <b>235.70 * 10 = 2357 USDC</b>。 但实际总卖出价为
              <b>1190.97 USDC</b>。 每张卡价值约为 <b>119.10 USDC</b>。
              <br />
              <br />
              <Center>
                <AspectRatio ratio={1.65} w={"700px"} textAlign={"center"}>
                  <Image src={"/img/zh/mootichi-price2.png"} alt="Card" />
                </AspectRatio>
              </Center>
              <br />
              <br />
              你卖的越多，你得到的每张卡的价格就越低。 即使您只卖 2
              张卡也是如此。
              <br />
              <br />
              现在让我们回到最开始的例子。
              <br />
              <br />
              <b>
                1) 市场不景气，卖卡的人比买卡的多，池子里有 40 张卡，120 美元。
                如果您现在赎回流动性，您将获得 20 张卡和 60 USDC。
              </b>
              <br />
              <br />
              您现在的总 USDC 价值是 60 + 20 * 3（现在价值 3 USDC 的卡）= 120
              USDC。 如果您只持有您的卡片和 USDC，您现在将拥有 100 + 10 * 3 =
              130 USDC。 这里的 10 USDC 是你理论上的无常损失。
              虽然你现在拥有更多的卡牌，所以当你把它们全部卖掉时，你会得到比你只卖
              10 张卡时更低的价格。 在这种情况下，您的无常损失将高于 10 USDC。
              <br />
              <br />
              <b>
                2) 市场非常火热，买卡的为人比卖卡的多，池子内现在有 10 张卡和
                500 USDC。 如果您现在赎回流动性，您将获得 5 张卡和 250 USDC。
              </b>
              <br />
              <br />
              你现在的总美元价值是 250 + 5 * 50 = 500 USDC。
              如果您只持有您的卡片和 USDC，您现在将拥有 100 + 10 * 50 = 600
              USDC。 这里的 100 USDC 是你理论上的无常损失。
              虽然现在您拥有的卡牌较少，但当您全部出售它们时，您会得到比全部出售
              10 张卡片更好的价格。 你的无常损失少于 100
              USDC，你现在甚至可能有利润，具体取决于流动池中的库存。
              <br />
              <br />
              损失或利润的确切数额计算起来更加复杂，这里我们不过多介绍。
              <br />
              <br />
              <Text fontWeight={100} fontSize={"2xl"} textAlign={"left"}>
                <b>总结</b>
              </Text>
              <br />
              利润和风险总是相伴的，即使我个人也不知道现在最好的投资策略是什么。
              虽然我确实有一些个人建议。这些完全是个人意见，风险自负。
              <br />
              <br />
              <b>1）如果您认为卡价会下跌，请不要增加流动性</b>
              <br />
              <br />
              如果您提供流动性并且卡价格下降，您将拥有更多的卡，并且将它们全部出售会降低价格。
              最好的选择是马上全部卖掉。
              <br />
              <br />
              <b>2）如果您认为您的卡价格会飙升，请不要增加流动性</b>
              <br />
              <br />
              与（1）同理，如果价格上涨太多，您将只拥有一小部分卡牌，由于无常损失而获得的利润较少。
              最好的选择是保持持有它们。
              <br />
              <br />
              <b>3）最赚钱的情况是卡牌的价格大致保持不变</b>
              <br />
              <br />
              正如我们在上面的示例中看到的，如果价格保持大致相同，您基本上不会有任何损失，并且所有流动性提供者手续费都是您的利润。
              <br />
              <br />
              <b>4) 银卡是一个不错的起点</b>
              <br />
              <br />
              银卡的最低买入价为 1.5
              USDC，如果低于此价格，人们就会购买它们并兑换为门票，并使价格回到
              1.5。 这意味着价格只能上涨或保持不变。
              <br />
              <br />
              <b>5）您拥有的单张卡牌数量越多，您从流动性中获得的收益就越多</b>
              <br />
              <br />
              显然，您在池中投入的越多，您获得的所有权就越多，作为回报，您从使用该卡进行的每笔交易中赚取的手续费就越多。
              更重要的是，快速大量卖出同一张卡而不对其价格产生太大影响并不是那么容易的事情。
              随着卡价的上涨，你也会慢慢将你在池中的卡牌“卖出”，以降低你持有太多卡牌的风险。
            </Text>
          </Stack>
        </Container>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <Container maxW={"3xl"} textColor={"white"}>
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 36 }}
        >
          <Heading fontWeight={100} fontSize={"4xl"} lineHeight={"110%"}>
            <b>Impermanent Loss in Skyweaver</b>
            <br />
          </Heading>
          <Text fontWeight={100} fontSize={"2xl"} textAlign={"left"}>
            <b>What is Impermanent Loss?</b>
          </Text>
          <Text margin-top={"0"} color={"black.500"} textAlign={"left"}>
            Impermanent loss happens when you provide liquidity to a liquidity
            pool, and the price of your deposited assets changes compared to
            when you deposited them. The bigger this change is, the more you are
            exposed to impermanent loss. In this case, the loss means the dollar
            value at the time of withdrawal compares to if you just hold it
            without adding to the liqudity pool.
            <br />
            <br />
            Let's say you add 10 cards, 100 USDC to a pool with 10 cards, 100
            USDC. Now you own 50% of the pool, the pool now has 20 cards, 200
            USDC. Your total dollar value is 200 USDC(10 USDC per card). There
            are 4 cases that may happen when you want to withdraw:
            <br />
            <br />
            <b>
              1) After a while the pool has 40 cards, 120 USDC, because people
              are selling more. If you withdraw your liuquidity now, you get 20
              cards and 60 USDC.
            </b>
            <br />
            <br />
            Your total dollar value now is 60 + 20 * 3(Cards now worth 3 USDC) =
            120 USDC. If you just hold your cards and USDC, you will now own 100
            + 10 * 3 = 130 USDC. The 10 USDC here is your impermanent loss.
            <br />
            <br />
            <b>
              2) After a while it has 10 cards and 500 USDC, because people are
              buying more. Now you withdraw with 5 cards and 250 USDC.
            </b>
            <br />
            <br />
            Your total dollar value now is 250 + 5 * 50 = 500 USDC. If you just
            hold your cards and USDC, you will now own 100 + 10 * 50 = 600 USDC.
            The 100 USDC here is your impermanent loss.
            <br />
            <br />
            <b>
              3) If trades are even, then it may has 20 cards and 300 USDC in
              the pool after a while, and you withdraw will be 10 cards and 150
              USDC.
            </b>
            <br />
            <br />
            Your total dollar value now is 150 + 10 * 15 = 300 USDC. If you just
            hold your cards and USDC, you will now own 100 + 10 * 15 = 250 USDC.
            You don't have any impermant loss and have 50 USDC profit.
            <br />
            <br />
            <b>
              4) If no one trades, your share will not change and you withdraw
              with 10 cards and 100 USDC. No gain no loss in this case.(Ignore
              the rounding issue)
            </b>
            <br />
            <br />
            This example shows you what impermanet loss is in the traditional
            AMM(automated market maker).
            <br />
            <br />
            <Text fontWeight={100} fontSize={"2xl"} textAlign={"left"}>
              <b>What is special about Impermanent Loss in Skyweaver?</b>
            </Text>
            <br />
            Compares to other cryptos, two things are special about Skyweaver
            liquidity pool.
            <br />
            <br />
            <b>
              1) The pool itself is also too small compare to standard crypto
              markets.
            </b>
            <br />
            <br />
            <b>
              2) The price is solely controlled by one liquidity pool - the
              offical marketplace.
            </b>
            <br />
            <br />
            These two things add up together, means you cannot simply calculate
            your cards value based on the price itself, especialy when you own
            more than 1 card.
            <br />
            You own 5 cards and the current sell price is 10 USDC/card, does not
            mean your total asset worth 50 USDC. When you actually sell it to
            the pool, the total price you get will be lower, the exact number
            will depends on how many cards and USDC are in the pool at that
            point.
            <br />
            <br />
            Let see a real example from the market here. Let's say you own 5
            copies of Mootichi, the sell price for it now is <b>194.02 USDC</b>.
            If you sell all of them, the paper value would be{" "}
            <b>194.02 * 5 = 988.4</b>. But the actual total sell price is{" "}
            <b>729.12</b>. Each card worth about <b>145.82 USDC</b>.
            <br />
            <br />
            <Center>
              <AspectRatio ratio={1.75} w={"700px"} textAlign={"center"}>
                <Image src={"/img/mootichi-price.png"} alt="Card" />
              </AspectRatio>
            </Center>
            <br />
            <br />
            If you owns 10 copies and sell them all, the paper value would be{" "}
            <b>194.02 * 10 = 1940.2</b>. But the actual total sell price is{" "}
            <b>1112.71</b>. Each card worth about <b>111.27 USDC</b>.
            <br />
            <br />
            <Center>
              <AspectRatio ratio={1.6} w={"700px"} textAlign={"center"}>
                <Image src={"/img/mootichi-price2.png"} alt="Card" />
              </AspectRatio>
            </Center>
            <br />
            <br />
            The more you sell, the less per card price you will get. And this is
            true even if you only sell 2 cards.
            <br />
            <br />
            Let's now go back to our liquidity examples.
            <br />
            <br />
            <b>
              1) After a while the pool has 40 cards, 120 USDC, because people
              are selling more. If you withdraw your liuquidity now, you get 20
              cards and 60 USDC.
            </b>
            <br />
            <br />
            Your total dollar value now is 60 + 20 * 3(Cards now worth 3 USDC) =
            120 USDC. If you just hold your cards and USDC, you will now own 100
            + 10 * 3 = 130 USDC. The 10 USDC here is your impermanent loss{" "}
            <b>on paper</b>.
            <br />
            Though you own more cards now, so when you sell them all, you will
            get a lower price then if you sell just 10 cards. Your impermanent
            loss will be higher than 10 USDC in this case.
            <br />
            <br />
            <b>
              2) After a while it has 10 cards and 500 USDC, because people are
              buying more. Now you withdraw with 5 cards and 250 USDC.
            </b>
            <br />
            <br />
            Your total dollar value now is 250 + 5 * 50 = 500 USDC. If you just
            hold your cards and USDC, you will now own 100 + 10 * 50 = 600 USDC.
            The 100 USDC here is your impermanent loss <b>on paper</b>.
            <br />
            Though now you own less cards, so when you sell them all you get a
            better price than sell all 10 cards. Your impermanent loss is less
            than 100 USDC, and you may even have profit now, depends on the
            pool.
            <br />
            <br />
            The exact amount of loss or profit is more complicated to calculate,
            so we won't cover them here. But you get the general idea.
            <br />
            <br />
            Case 3 and 4 are the same as before.
            <br />
            <br />
            <Text fontWeight={100} fontSize={"2xl"} textAlign={"left"}>
              <b>Summary</b>
            </Text>
            <br />
            There's always risk involved with profits, even I personally don't
            know what's the best strategy to invest right now. Though I do have
            some personal advices. These are totally personal opinion, so take
            them at your own risk.
            <br />
            <br />
            <b>
              1) Don't add liquidity if you think the card price will go down
            </b>
            <br />
            <br />
            You will owns more cards if you provide liquidity and card price
            goes down, and selling all of them will decrease the price more. The
            best option is to just sell them all.
            <br />
            <br />
            <b>
              2) Don't add liquidity if you think the price of your cards will
              skyrocket
            </b>
            <br />
            <br />
            Same as 1), if the price goes up too much, you will own only a small
            portion of your cards and make less profit due to impermanent loss.
            The best option is to just hold them.
            <br />
            <br />
            <b>
              3) The most profitable case is when the price of the card stays
              about the same
            </b>
            <br />
            <br />
            As we saw in our examples above, you basically won't lose any money
            if the price keeps about the same, and all the liquidity provider
            fees are your profits.
            <br />
            <br />
            <b>4) Silver cards is a good place to start with</b>
            <br />
            <br />
            Silver cards have a bottm buy price at 1.5 USDC, becuase if it's
            lower than that people will just buy them and exchange it for
            conquest ticket and make the price back to 1.5. This means the price
            can only go up or stay the same.
            <br />
            <br />
            <b>
              5) The more copies you have, the more profits you can get from
              liquidity
            </b>
            <br />
            <br />
            Obviously, the more you put in the pool, the more ownership you will
            get, and in return, the more profit you earn from each trade with
            that card. More importantly, it's not that easy to quickly sell a
            big amount of the same card without affecting the price too much.
            With the card price going up, you will also slowly "sell" your cards
            in the pool to reduce your risk of holding too many cards.
          </Text>
        </Stack>
      </Container>
    </React.Fragment>
  );
}
