import {
  Box,
  chakra,
  Container,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
  VisuallyHidden,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { FaDiscord, FaTwitter } from "react-icons/fa";
import SupportButton from "./SupportButton";
import SupportModal from "./SupportModal";

export default function Footer() {
  const {
    isOpen: isSupportOpen,
    onOpen: onSupportOpen,
    onClose: onSupportClose,
  } = useDisclosure();
  const SocialButton = ({
    children,
    label,
    href,
  }: {
    children: ReactNode;
    label: string;
    href: string;
  }) => {
    return (
      <chakra.button
        bg={"white"}
        rounded={"full"}
        w={8}
        h={8}
        cursor={"pointer"}
        as={"a"}
        href={href}
        display={"inline-flex"}
        alignItems={"center"}
        justifyContent={"center"}
        _hover={{
          bg: "white",
        }}
      >
        <VisuallyHidden>{label}</VisuallyHidden>
        {children}
      </chakra.button>
    );
  };

  return (
    <Box
      bg={"black.900"}
      as="footer"
      role="contentinfo"
      mx="auto"
      w="100%"
      py="12"
      px={{ base: "4", md: "8" }}
    >
      <Box borderTopWidth={1} borderStyle={"solid"} borderColor={"gray.200"}>
        <Container
          as={Stack}
          maxW={"6xl"}
          py={4}
          direction={{ base: "column", md: "row" }}
          spacing={4}
          justify={{ base: "center", md: "space-between" }}
          align={{ base: "center", md: "center" }}
        >
          <Text color={"white"}>© 2023 SkyweaverSwap. All rights reserved</Text>
          <Stack direction={"row"} spacing={6}>
            <SocialButton
              label={"Discord"}
              href={"https://discord.gg/whCHsjQSyg"}
            >
              <FaDiscord />
            </SocialButton>
            <SocialButton
              label={"Twitter"}
              href={"https://twitter.com/skyweaverswap"}
            >
              <FaTwitter />
            </SocialButton>
          </Stack>
          <SupportModal isOpen={isSupportOpen} onClose={onSupportClose} />
        </Container>
      </Box>
    </Box>
  );
}
