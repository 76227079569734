import { Button, Text, Box } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FiShoppingBag } from "react-icons/fi";
import { useGlobalState } from "../../../hooks/GlobalState";

export default function ShoppingCartIcon({ onCartOpen, mode }) {
  const [globalState, dispatch] = useGlobalState();
  const [count, setCount] = useState(0);

  const handleCartOpen = () => {
    for (const [key, value] of globalState.shoppingCart.entries()) {
      value["price"] = "";
    }
    onCartOpen();
  };

  useEffect(() => {
    let newCount = 0;
    for (const [key, value] of globalState.shoppingCart.entries()) {
      if (mode === "bulkBuy" || mode === "bulkSell") {
        // newCount += value["count"];
        newCount += 1;
      } else {
        newCount += 1;
      }
    }
    if (count != newCount) {
      setCount(newCount);
    }
  }, [globalState.shoppingCart]);

  return (
    <Box
      position="fixed"
      right="30px"
      bottom="30px"
      w="60px"
      h="60px"
      p="10px"
      aria-label="Shopping cart"
      zIndex={3}
    >
      <Text
        position={"absolute"}
        backgroundColor="red"
        zIndex={4}
        borderRadius="50%"
        w="32px"
        h="32px"
        alignContent={"center"}
        textAlign={"center"}
        verticalAlign={"middle"}
        marginTop="-5px"
        marginLeft="35px"
        textColor={"white"}
        paddingTop="4px"
        onClick={handleCartOpen}
      >
        {count}
      </Text>
      <Button w="60px" h="60px" onClick={handleCartOpen}>
        <FiShoppingBag size={24} />
      </Button>
    </Box>
  );
}
