import {
  Box,
  Button,
  Flex,
  Link,
  Modal,
  Image,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  AspectRatio,
  HStack,
  VStack,
  Spacer,
  Input,
  Divider,
  Container,
} from "@chakra-ui/react";
import { useState } from "react";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";

type Props = {
  isOpen: any;
  onClose: any;
  card: any;
};

export default function CardDataModal({ isOpen, onClose, card }: Props) {
  const { t, i18n } = useTranslation("translation");
  const [days, setDays] = useState(60);
  const id = card["baseID"];
  const type = card["rarity"] === "gold" ? 2 : 1;

  let cardPriceMovement;
  let totalCardPurchase;
  let totalCardSale;

  if (i18n.resolvedLanguage == "zh") {
    cardPriceMovement = `https://dune.xyz/embeds/441522/839545/71747fc6-d50f-4767-ac50-4b2c6af58754?ID=${id}&Days=${days}&Type=${type}`;
    totalCardPurchase = `https://dune.xyz/embeds/441538/839582/573f119d-5ef6-4d03-b5b8-c25ae782f886?ID=${id}&Days=${days}&Type=${type}`;
    totalCardSale = `https://dune.xyz/embeds/441538/839583/380d8dc8-f8d2-47b9-94e2-1fca68636e08?ID=${id}&Days=${days}&Type=${type}`;
  } else {
    cardPriceMovement = `https://dune.xyz/embeds/394473/752680/1476b1ea-dbfa-4129-9ad4-20fdb222d964?ID=${id}&Days=${days}&Type=${type}`;
    totalCardPurchase = `https://dune.xyz/embeds/394431/752590/e83e5c10-1ff5-449b-9937-7b9fd534c355?ID=${id}&Days=${days}&Type=${type}`;
    totalCardSale = `https://dune.xyz/embeds/394431/752591/09783c2f-b72c-41d0-94c8-b24d36cd7827?ID=${id}&Days=${days}&Type=${type}`;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="3xl">
      <ModalOverlay />
      <ModalContent border="1px" borderStyle="solid" borderRadius="3xl">
        <ModalHeader color="black" px={4} fontSize="lg" fontWeight="bold">
          {card["name"]}
        </ModalHeader>
        <ModalCloseButton
          color="black"
          fontSize="sm"
          _hover={{
            color: "whiteAlpha.700",
          }}
        />
        <ModalBody pt={0} px={4}>
          <Box borderRadius="3xl" px={5} pt={4} pb={2} mb={3}>
            <VStack>
              <Container>
                <HStack>
                  <Spacer />
                  <Text>{t("translation:data.showDataForDays-1")}</Text>
                  <Input
                    w={"70px"}
                    type="number"
                    value={days}
                    onChange={(e) => setDays(parseInt(e.target.value))}
                  />
                  <Text>{t("translation:data.showDataForDays-2")}</Text>
                  <Spacer />
                </HStack>
              </Container>
              <Box
                as="iframe"
                h={{ base: "200px", md: "400px" }}
                w="100%"
                src={cardPriceMovement}
              ></Box>
              <HStack display={{ base: "none", md: "flex" }}>
                <Box as="iframe" h="s" w="100%" src={totalCardPurchase}></Box>
                <Box as="iframe" h="s" w="100%" src={totalCardSale}></Box>
              </HStack>

              <VStack display={{ base: "flex", md: "none" }}>
                <Box as="iframe" h="s" w="100%" src={totalCardPurchase}></Box>
                <Box as="iframe" h="s" w="100%" src={totalCardSale}></Box>
              </VStack>
              <Text>
                {t("translation:data.forkedFrom")}{" "}
                <Link
                  color="blue"
                  href="https://dune.xyz/fleam/Gold-Card-Price-Movement"
                >
                  fleam
                </Link>
              </Text>
            </VStack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
