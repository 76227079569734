import { Button, Box, Spacer } from "@chakra-ui/react";
import { useGlobalState } from "../hooks/GlobalState";
import { web3Modal } from "../constants/web3";
import { useEffect, useState } from "react";
import {
  EXCHANGE_ABI,
  EXCHANGE_ADDRESS,
  QUERY_ABI,
  SKYWEAVER_TOKEN_CONTRACT_ADDRESS,
  USDC_ABI,
  USDC_CONTRACT,
} from "./constant";
import ReactGA from "react-ga";
import { ethers } from "ethers";
import { sequence } from "0xsequence";
import { enableExtraWalletLogin } from "../constants/flags";
import { formatUnits } from "ethers/lib/utils";
import { useTranslation } from "react-i18next";
import styles from "../css/web3.module.css";

const wallet = sequence.initWallet({ defaultNetwork: 'polygon', projectAccessKey: 'e7takz1SHwsbvUN7ifZIyTLGAAAAAAAAA' })

export default function ConnectButton({ showOther, connectCallback = null }) {
  const [globalState, dispatch] = useGlobalState();
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation("translation");

  async function handleConnectSequence(manually: boolean) {
    ReactGA.set({ dimension2: "Sequence" });
    if (!manually) {
      if (!wallet.isConnected()) {
        setLoading(false);
        dispatch({ loadingAccount: false });
        return false;
      }
      dispatch({
        sequenceWallet: wallet,
      });
      const account = await wallet.getAddress();
      await handleContractConnection(wallet, account);
      dispatch({ loadingAccount: false });
      return true;
    }
    if (!wallet.isConnected() && manually) {
      const connectDetails = await wallet.connect({
        app: "SkyweaverSwap",
        authorize: true,
      });

      if (connectDetails.connected) {
        const account = await wallet.getAddress();
        await handleContractConnection(wallet, account);
        dispatch({
          sequenceWallet: wallet,
        });
      }
    }
    dispatch({ loadingAccount: false });
    return true;
  }

  async function handleContractConnection(wallet: any, account: string) {
    try {
      const signer = wallet.getSigner();
      const usdcContract = new ethers.Contract(USDC_CONTRACT, USDC_ABI, signer);

      const exchangeTradeContract = await new ethers.Contract(
        EXCHANGE_ADDRESS,
        EXCHANGE_ABI,
        signer
      );
      const skyweaverQueryContract = await new ethers.Contract(
        SKYWEAVER_TOKEN_CONTRACT_ADDRESS,
        QUERY_ABI,
        signer
      );

      const balance = await usdcContract.balanceOf(account);
      // if (account != state.account) {
      //   await loadCards();
      // }

      const allowance = await usdcContract.allowance(account, EXCHANGE_ADDRESS);
      const needApproval = allowance < 100000000;
      dispatch({
        account: account,
        balance: parseFloat(formatUnits(balance, 6)).toFixed(2),
        usdcContract: usdcContract,
        exchangeTradeContract: exchangeTradeContract,
        skyweaverQueryContract: skyweaverQueryContract,
        needApproval: needApproval,
      });
      ReactGA.set({ userId: account });
    } catch (error) {}
  }

  async function handleConnectWallet() {
    ReactGA.set({ dimension2: "Web3Modal" });
    try {
      const provider = await web3Modal.connect();
      const ethersProvider = new ethers.providers.Web3Provider(provider);
      const accounts = await ethersProvider.listAccounts();
      ethersProvider.pollingInterval = 1800000;
      const account = accounts[0];

      await handleContractConnection(ethersProvider, account);
      dispatch({ loadingAccount: false });
    } catch (error) {}
  }

  async function connectDefaultProvider() {
    const provider = new ethers.providers.InfuraProvider("matic", {
      projectId: "9a7ede0dd77b44a7a8f22830edfef1e8",
    });

    const exchangeTradeContract = await new ethers.Contract(
      EXCHANGE_ADDRESS,
      EXCHANGE_ABI,
      provider
    );
    const skyweaverQueryContract = await new ethers.Contract(
      SKYWEAVER_TOKEN_CONTRACT_ADDRESS,
      QUERY_ABI,
      provider
    );

    dispatch({
      provider: provider,
      exchangeTradeContract: exchangeTradeContract,
      skyweaverQueryContract: skyweaverQueryContract,
    });
  }

  useEffect(() => {
    (async () => {
      const accountLoaded = await handleConnectSequence(false);
      if (!accountLoaded) {
        if (enableExtraWalletLogin) {
          const provider = localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER");
          if (provider) {
            if (provider === '"walletconnect"') {
              web3Modal.clearCachedProvider();
              localStorage.setItem("WEB3_CONNECT_CACHED_PROVIDER", "");
              window.location.reload();
              return;
            }
            await handleConnectWallet();
            return;
          }
        }
        connectDefaultProvider();
      }
    })();
  }, []);
  showOther = enableExtraWalletLogin && showOther;
  const otherOptionButton = showOther ? (
    <Box>
      <Spacer mt={2} />
      <button
        onClick={() => {
          handleConnectWallet();
        }}
        className={[
          styles.buttonLink,
          styles.buttonGradientBorder,
          styles.buttonGlow,
          styles.largeButton,
          styles.thinButton,
        ].join(" ")}
      >
        {t("translation:market.connectToOtherWallet")}
      </button>
    </Box>
  ) : null;
  return (
    <Box display={globalState.loadingAccount ? "none" : "block"}>
      <button
        onClick={() => {
          handleConnectWallet();
          if (connectCallback) {
            connectCallback();
          }
          // handleConnectSequence(true);
        }}
        className={[
          styles.buttonLink,
          styles.buttonGradientBorder,
          styles.buttonGlow,
          styles.largeButton,
          styles.thinButton,
        ].join(" ")}
      >
        {t("translation:market.connectToWallet")}
      </button>
      {otherOptionButton}
    </Box>
  );
}
