import {
  Container,
  Stack,
  Box,
  Heading,
  Text,
  Link,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
} from "@chakra-ui/react";
import React from "react";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";

export default function FAQ() {
  const { t, i18n } = useTranslation("translation");
  ReactGA.pageview(window.location.pathname);

  if (i18n.resolvedLanguage === "zh") {
    return (
      <React.Fragment>
        <Container maxW={"3xl"} textColor={"white"}>
          <Stack
            as={Box}
            textAlign={"center"}
            spacing={{ base: 8, md: 10 }}
            py={{ base: 20, md: 36 }}
          >
            <Heading fontWeight={100} fontSize={"4xl"} lineHeight={"110%"}>
              <b>{t("translation:guide.index.faqTitle")}</b>
              <br />
            </Heading>
            <Accordion allowToggle>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box fontWeight={"bold"} flex="1" textAlign="left">
                      为什么网站上的卡牌价格与游戏内的不同？
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Text margin-top={"0"} color={"black.500"} textAlign={"left"}>
                    那是因为与游戏市场相比，我们收取的费用更少，滑点也更小。
                    <br />
                    <br />
                    <p></p>
                    你在游戏中看到的价格是
                    <p></p>
                    <b>
                      卡牌价格 - 5% 开发商手续费 - 1% 流动性提供者手续费 - 1%
                      前端手续费 - 3% 滑点
                    </b>
                    <p></p>
                    <br />
                    你在 SkyweaverSwap 看到的价格是
                    <p></p>
                    <b>
                      卡价格 - 5% 开发商手续费 - 1% 流动性提供者手续费 - 0.5%
                      前端手续费 - ~1% 滑点。{" "}
                    </b>
                    <br />
                    <br />
                    <b>5% 的开发商手续费</b>支付给 Horizon
                    Games(Skyweaver开发商)。
                    <br />
                    <b>1% 的流动性提供者手续费</b>支付给流动性提供者。
                    <br />
                    <b>0.5% 的前端手续费</b>支付给 SkyweaverSwap。
                    <br />
                    <b>滑点</b>
                    是指当您的订单执行的价格与请求的价格不匹配时的术语。
                    这仅在您下订单时市场价格发生变化时使用。
                    其他时候，它会退还到您的帐户。（也就是您收到的钱比您看到的价格多）
                  </Text>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box fontWeight={"bold"} flex="1" textAlign="left">
                      SkyweaverSwap是Skyweaver的官方网站么？
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Text margin-top={"0"} color={"black.500"} textAlign={"left"}>
                    不，这不是一个官方的 Horizon 项目。 本网站由 Skyweaver
                    玩家创建。 它建立在 Skyweaver 的{" "}
                    <Link
                      textDecoration={"underline"}
                      href="https://github.com/0xsequence/niftyswap"
                    >
                      niftyswap
                    </Link>{" "}
                    智能合约之上。
                    您使用与游戏内市场完全相同的流动池进行交易，SkyweaverSwap
                    的价格更优惠，因为它免除了您在游戏内市场需要支付的一些前端手续费，并设置了更小的滑点。
                    当您在 SkyweaverSwap
                    上交易时，您会看到游戏市场中的卡牌库存相应发生变化。
                  </Text>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box fontWeight={"bold"} flex="1" textAlign="left">
                      为什么我将 1 张牌加入流动池后显示我在池中拥有 0.99 张？
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Text margin-top={"0"} color={"black.500"} textAlign={"left"}>
                    在你在增加流动性后你会对应拥有一定数量的流动池股份。
                    SkyweaverSwap
                    进行了一些计算并将该份额转换为您拥有的USDC/卡牌数量以便于理解。
                    根据池中的总股份和您拥有的股份数量，由于四舍五入，您实际拥有的卡牌数量会有一定的误差。
                    例如，您将 1 张卡和 1.5 USDC 添加到流动池中，并获得该卡的
                    1,576,942 股。该池共有 63,061,942 股，目前池中有 39.99
                    张牌。您在池中拥有的卡将是：
                    <br />
                    <br />
                    <b>1,576,942 / 63,061,942 * 39.99 = 0.99999950176</b>
                    <br />
                    <br />
                    即使您将 1 张卡添加到池中，您实际拥有的股份这算也可能不到 1
                    张整卡。 因为股份份额必须是整数。如果您再多获得 1
                    股，您将拥有：
                    <br />
                    <br />
                    <b>1,576,943 / 63,061,942 * 39.99 = 1.0000001359</b>
                    <br />
                    <br />
                    超过1张卡。因此，当您添加流动性时可能会产生一个微小的折损，在本例中为
                    0.00000049824 卡。在这种情况下，SkyweaverSwap
                    会向您显示您拥有 0.99 张该卡牌。
                    <br />
                    <br />
                    对于上面的示例，如果您从池中提取所有份额，您将获得 0.99
                    卡（0.01 是一张卡的最小单位），并且流动池不会为您补偿缺失的
                    0.00999950176 张卡。
                    因此，当您提取所有流动性时，您将失去池中的 .00999950176
                    卡。不幸的是，这是 Skyweaver
                    的智能合约设计中的一个缺陷，导致卡的最小单位为0.01，
                    从而让你在赎回流动性时会产生一些微小的折损。
                    当您只提取一部分流动性时，您不会有任何损失。这种损失只会在您提取所有流动性时发生。
                  </Text>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Stack>
        </Container>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Container maxW={"3xl"} textColor={"white"}>
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 36 }}
        >
          <Heading fontWeight={100} fontSize={"4xl"} lineHeight={"110%"}>
            <b>FAQ</b>
            <br />
          </Heading>
          <Accordion allowToggle>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box fontWeight={"bold"} flex="1" textAlign="left">
                    Why the price on the webiste is different than the in-game
                    market?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Text margin-top={"0"} color={"black.500"} textAlign={"left"}>
                  That's because we are charging less fees and setting a smaller
                  slippage than the in game market.
                  <br />
                  <br />
                  <p></p>
                  The price you see in game is
                  <p></p>
                  <b>
                    Card Price - 5% loyalty fee - 1% liquidity provider fee - 1%
                    frontend fee - 3% slippage
                  </b>
                  .<p></p>
                  <br />
                  The price you see in SkyweaverSwap is
                  <p></p>
                  <b>
                    Card Price - 5% loyalty fee - 1% liquidity provider fee -
                    0.5% frontend fee - ~1% slippage
                  </b>
                  .
                  <br />
                  <br />
                  <b>5% loyalty fee</b> is always paid to Horizon
                  Games(Skyweaver Maker).
                  <br />
                  <b>1% liquidity provider fee</b> is paid to the liquidity
                  provider.
                  <br />
                  <b>0.5% frontend fee</b> is paid to SkyweaverSwap.
                  <br />
                  <b>Slippage</b> is the term for when the price at which your
                  order is executed does not match the price at which it was
                  requested. This will only be used when the market price shift
                  at the time of your order. Other times it will be refunded to
                  your account.(aka you will receive more money than the price
                  you saw)
                </Text>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box fontWeight={"bold"} flex="1" textAlign="left">
                    Is the website build by Horizon?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Text margin-top={"0"} color={"black.500"} textAlign={"left"}>
                  No, this is not an official Horizon project. This website is
                  built by a skyweaver player. It's built on top of the
                  Skyweaver's{" "}
                  <Link
                    textDecoration={"underline"}
                    href="https://github.com/0xsequence/niftyswap"
                  >
                    niftyswap
                  </Link>{" "}
                  smart contract. You are trading with the exact same pool as
                  in-game market, SkyweaverSwap's price is better because it
                  gets rid of some of the frontend fees you need to pay in the
                  in-game market, and set a smaller slippage. You will see card
                  stocks change in the game market as you trade on
                  SkyweaverSwap.
                </Text>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box fontWeight={"bold"} flex="1" textAlign="left">
                    Why it shows I own 0.99 card in the pool when I add 1 to the
                    liquidity?
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Text margin-top={"0"} color={"black.500"} textAlign={"left"}>
                  What happens under the hood is you owns a certain amount of
                  shares for the pool after adding liquidity. SkyweaverSwap
                  makes some calculation and convert that share into cash/card
                  you own for easier understanding. Based on how many total
                  shares in the pool and how many you own, you may not own exact
                  1 card due to rounding. For examples, you add 1 card and 1.5
                  USDC to a pool and get back 1,576,942 shares for that card.
                  The pool has 63,061,942 total shares in it, and currently
                  there's 39.99 cards in the pool. The card you own in the pool
                  will be:
                  <br />
                  <br />
                  <b>1,576,942 / 63,061,942 * 39.99 = 0.99999950176</b>
                  <br />
                  <br />
                  Not exactly 1 card, even though you add 1 card to the pool.
                  <br />
                  <br />
                  This is because shares have to be integers. If you have 1 more
                  share, you will own:
                  <br />
                  <br />
                  <b>1,576,943 / 63,061,942 * 39.99 = 1.0000001359</b>
                  <br />
                  <br />
                  More than 1 card. So there's a very tiny loss when you add
                  liquidity, in this case 0.00000049824 card. SkyweaverSwap will
                  show you as if you own 0.99 card in this case.
                  <br />
                  <br />
                  For the example above, if you withdraw all your shares from
                  the pool, you will get 0.99 card(0.01 is the minimal unit for
                  a card), and the pool <b>will not</b> compensate you with more
                  USDC for the other 0.00999950176 card for you. So you will
                  lose the .00999950176 card in the pool when you withdraw all
                  your liquidity. This is unfortunately a flaw in the token
                  design of Skyweaver cards, where it only has two decimal
                  places.
                  <br />
                  <br />
                  When you only withdraw a portion of your liquidity, there
                  won't be loss for you. This loss will only happen when you{" "}
                  <b>withdraw all your liquidity</b>.
                </Text>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Stack>
      </Container>
    </React.Fragment>
  );
}
