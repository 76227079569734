import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  useColorModeValue,
  DrawerCloseButton,
  Stack,
  Heading,
  HStack,
  Icon,
  Button,
  Text,
  useDisclosure,
  Spinner,
  Link,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { CartItem } from "./CartItem";
import { useGlobalState } from "../../../hooks/GlobalState";
import { useEffect, useState } from "react";
import {
  sksBuyPriceRate,
  sksFeeRate,
  sksSellPriceRate,
} from "../../../constants/exchange";
import Decimal from "decimal.js";
import styles from "../../../css/web3.module.css";
import { BigNumber, ethers } from "ethers";
import ReactGA from "react-ga";
import { author_wallet, EXCHANGE_ADDRESS } from "../../constant";
import {
  AddLiquidityObj,
  AddLiquidityType,
  getSellTokenData20,
  methodsSignature20,
  RemoveLiquidityObj,
  RemoveLiquidityType,
} from "../../typings";
import uuid from "react-uuid";

export default function ShoppingCart({
  isOpen,
  onOpen,
  onClose,
  mode,
  succeedCallback,
}) {
  const [errorMessage, setErrorMessage] = useState("");
  const [globalState, dispatch] = useGlobalState();
  const [total, setTotal] = useState(-1);
  const { t, i18n } = useTranslation("translation");

  useEffect(() => {
    var totalPrice = new Decimal(0);

    for (const [key, value] of globalState.shoppingCart.entries()) {
      if (!value["price"]) {
        setTotal(-1);
        return;
      }
      totalPrice = totalPrice.plus(new Decimal(value["price"]));
    }
    setTotal(totalPrice.toNumber());
  }, [globalState.shoppingCart, isOpen]);

  const onTradeComplete = () => {
    onClearCart();
    onClose();
    succeedCallback();
  };

  const onClearCart = () => {
    const newCart = new Map();
    dispatch({ shoppingCart: newCart });
  };

  const onCheckout = async () => {
    if (new Decimal(total).equals(-1)) {
      setErrorMessage(t("translation:market.error.waitForPriceUpdate"));
      return;
    }
    if (mode === "bulkBuy" || mode === "bulkAddLiquidity") {
      if (new Decimal(total).comparedTo(globalState.balance) > 0) {
        setErrorMessage(t("translation:market.error.notEnoughUSDCToBuy"));
        return;
      }
    }
    if (errorMessage !== "") {
      setErrorMessage("");
    }
    const card_list = [];
    const card_id_list = [];
    const card_count_list = [];
    let card_price = new Decimal(0);
    const fee_wallet_list = [];
    const fee_list = [];
    for (const [key, value] of globalState.shoppingCart.entries()) {
      const cardItem = {
        ID: key,
        amount: value["count"],
        cardDetail: value["card"],
        price: value["price"],
      };
      card_list.push(cardItem);
    }
    card_list.sort((a, b) => parseFloat(a["ID"]) - parseFloat(b["ID"]));
    card_list.forEach((cardItem) => {
      card_id_list.push(ethers.BigNumber.from(cardItem["ID"]));
      card_count_list.push(
        ethers.BigNumber.from(
          new Decimal(cardItem.amount).times(100).floor().toNumber()
        )
      );
      card_price = card_price.plus(
        new Decimal(cardItem.price).times(1000000).ceil()
      );
      fee_list.push(
        ethers.BigNumber.from(
          new Decimal(cardItem.price)
            .times(1000000)
            .times(sksFeeRate)
            .floor()
            .toNumber()
        )
      );
      fee_wallet_list.push(author_wallet);
    });
    if (mode === "bulkBuy") {
      ReactGA.event({
        category: "Asset",
        action: "Bulk Buy transfered to wallet",
      });
      const transfer = await globalState.exchangeTradeContract.buyTokens(
        card_id_list,
        card_count_list,
        ethers.BigNumber.from(card_price.toNumber()),
        // 2h as deadline
        ethers.BigNumber.from(Math.floor(Date.now() / 1000) + 60 * 60 * 2),
        globalState.account,
        fee_wallet_list,
        fee_list
      );
      try {
        await transfer.wait();

        for (const [key, value] of globalState.shoppingCart.entries()) {
          const card = value["card"];
          ReactGA.event({
            category: "Asset",
            action: "Successful Bulk Buy Trade",
            label: card["rarity"],
            value: new Decimal(value["price"]).times(100).floor().toNumber(),
            dimension1: card["name"],
          });
          ReactGA.plugin.require("ecommerce");
          ReactGA.plugin.execute("ecommerce", "addTransaction", {
            id: uuid(), // Transaction ID. Required.
            name: card["name"], // Product name. Required.
            sku: card["name"], // SKU/code.
            category: card["rarity"], // Category or variation.
            price: value["price"],
            quantity: value["count"],
            revenue: new Decimal(value["price"]).toNumber().toFixed(2), // Grand Total.
          });
          ReactGA.plugin.execute("ecommerce", "send", null);
          ReactGA.plugin.execute("ecommerce", "clear", null);
        }
        onTradeComplete();
      } catch (error) {
        ReactGA.event({
          category: "Asset",
          action: "Failed Bulk Buy after transfered to wallet",
        });
      }
    }
    if (mode === "bulkSell") {
      ReactGA.event({
        category: "Asset",
        action: "Bulk Sell transfered to wallet",
      });
      const transfer =
        await globalState.skyweaverQueryContract.safeBatchTransferFrom(
          globalState.account,
          EXCHANGE_ADDRESS,
          card_id_list,
          card_count_list,
          getSellTokenData20(
            globalState.account,
            ethers.BigNumber.from(card_price.toNumber()),
            // 2h as deadline
            Math.floor(Date.now() / 1000) + 60 * 60 * 2,
            fee_wallet_list,
            fee_list
          )
        );
      try {
        await transfer.wait();
        for (const [key, value] of globalState.shoppingCart.entries()) {
          const card = value["card"];
          ReactGA.event({
            category: "Asset",
            action: "Successful Bulk Sell Trade",
            label: card["rarity"],
            value: new Decimal(value["price"]).times(100).floor().toNumber(),
            dimension1: card["name"],
          });
          ReactGA.plugin.require("ecommerce");
          ReactGA.plugin.execute("ecommerce", "addTransaction", {
            id: uuid(), // Transaction ID. Required.
            name: card["name"], // Product name. Required.
            sku: card["name"], // SKU/code.
            category: card["rarity"], // Category or variation.
            price: value["price"],
            quantity: value["count"],
            revenue: new Decimal(value["price"]).toNumber().toFixed(2), // Grand Total.
          });
          ReactGA.plugin.execute("ecommerce", "send", null);
          ReactGA.plugin.execute("ecommerce", "clear", null);
        }
        onTradeComplete();
      } catch (error) {
        ReactGA.event({
          category: "Asset",
          action: "Failed Bulk Sell after transfered to wallet",
        });
      }
    }
    if (mode === "bulkAddLiquidity") {
      // baseAmountsToAdd: BigNumber[], deadline: number
      const getAddLiquidityData = (
        maxCurrency: BigNumber[],
        deadline: number
      ) => {
        const addLiquidityObj = { maxCurrency, deadline } as AddLiquidityObj;
        return ethers.utils.defaultAbiCoder.encode(
          ["bytes4", AddLiquidityType],
          [methodsSignature20.ADDLIQUIDITY, addLiquidityObj]
        );
      };
      const card_cap_list = [];
      card_list.forEach((cardItem) => {
        card_cap_list.push(
          ethers.BigNumber.from(
            new Decimal(cardItem.price)
              .add(0.01)
              .times(1000000)
              .ceil()
              .toNumber()
          )
        );
      });
      ReactGA.event({
        category: "Asset",
        action: "Bulk Add Liquidity transfered to wallet",
      });
      const transfer =
        await globalState.skyweaverQueryContract.safeBatchTransferFrom(
          globalState.account,
          EXCHANGE_ADDRESS,
          card_id_list,
          card_count_list,
          getAddLiquidityData(
            card_cap_list,
            // 2h as deadline
            Math.floor(Date.now() / 1000) + 60 * 60 * 2
          )
        );
      try {
        await transfer.wait();
        for (const [key, value] of globalState.shoppingCart.entries()) {
          const card = value["card"];
          ReactGA.event({
            category: "Asset",
            action: "Successful Bulk Add Liquidity",
            label: card["rarity"],
            value: new Decimal(value["price"]).times(100).floor().toNumber(),
            dimension1: card["name"],
          });
        }
        onTradeComplete();
      } catch (error) {
        ReactGA.event({
          category: "Asset",
          action: "Failed Bulk Add Liquidity after transfered to wallet",
        });
      }
    }
    if (mode === "bulkRemoveLiquidity") {
      // baseAmountsToAdd: BigNumber[], deadline: number as epoch time in seconds
      const getRemoveLiquidityData = (
        minCurrency: BigNumber[],
        minTokens: BigNumber[],
        deadline: number
      ) => {
        const removeLiquidityObj = {
          minCurrency,
          minTokens,
          deadline,
        } as RemoveLiquidityObj;

        return ethers.utils.defaultAbiCoder.encode(
          ["bytes4", RemoveLiquidityType],
          [methodsSignature20.REMOVELIQUIDITY, removeLiquidityObj]
        );
      };
      const card_min_cap_list = [];
      const card_contract_count_list = [];
      card_list.forEach((cardItem) => {
        card_min_cap_list.push(
          ethers.BigNumber.from(ethers.BigNumber.from("0"))
        );
        const card = cardItem["cardDetail"];
        const contract_amount = ethers.BigNumber.from(
          new Decimal(cardItem.amount)
            .div(
              new Decimal(card["pool_ownedCardInPool"]).toDP(
                2,
                Decimal.ROUND_DOWN
              )
            )
            .times(card["pool_ownedShare"].toNumber())
            .ceil()
            .toNumber()
        );
        card_contract_count_list.push(contract_amount);
      });
      ReactGA.event({
        category: "Asset",
        action: "Remove Liquidity transfered to wallet",
      });
      console.log(card_id_list);
      console.log(card_count_list);
      console.log(card_min_cap_list);
      console.log(card_contract_count_list);
      const transfer =
        await globalState.exchangeTradeContract.safeBatchTransferFrom(
          globalState.account,
          EXCHANGE_ADDRESS,
          card_id_list,
          card_contract_count_list,
          getRemoveLiquidityData(
            card_min_cap_list,
            card_count_list,
            // 30min as deadline
            Math.floor(Date.now() / 1000) + 60 * 30
          )
        );
      try {
        await transfer.wait();
        for (const [key, value] of globalState.shoppingCart.entries()) {
          const card = value["card"];
          ReactGA.event({
            category: "Asset",
            action: "Successful Bulk Remove Liquidity",
            label: card["rarity"],
            value: new Decimal(value["price"]).times(100).floor().toNumber(),
            dimension1: card["name"],
          });
        }
        onTradeComplete();
      } catch (error) {
        ReactGA.event({
          category: "Asset",
          action: "Failed Remove Liquidity after transfered to wallet",
        });
      }
    }
  };

  let checkoutText;
  let subtotalText;
  switch (mode) {
    case "bulkBuy":
      checkoutText = t("translation:market.bulk.cart.checkout");
      subtotalText = t("translation:market.action.buy");
      break;
    case "bulkSell":
      checkoutText = t("translation:market.bulk.cart.checkout");
      subtotalText = t("translation:market.action.sell");
      break;
    case "bulkAddLiquidity":
      checkoutText = t("translation:market.bulk.cart.checkoutLiquidity");
      subtotalText = t("translation:market.action.addLiquidity");
      break;
    case "bulkRemoveLiquidity":
      checkoutText = t("translation:market.bulk.cart.checkoutLiquidity");
      subtotalText = t("translation:market.action.withdrawLiquidity");
  }

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      onOverlayClick={onClose}
      size="md"
      /*`trapFocus` and `blockScrollOnMount` are only switched off so that the preview works properly. */
      blockScrollOnMount={false}
      trapFocus={false}
    >
      <DrawerOverlay />
      <DrawerContent
        bg={useColorModeValue("white", "gray.800")}
        overflowY="auto"
      >
        <DrawerCloseButton
          size="lg"
          right={{ base: "4", md: "8" }}
          top="4"
          bg="inherit"
        />
        <Stack
          padding={{ base: "6", md: "10" }}
          height="full"
          spacing="8"
          overflowY="auto"
        >
          <Heading size="md">
            <HStack>
              <Text>
                {t("translation:market.bulk.cart.shoppingCart")} (
                {globalState.shoppingCart.size}{" "}
                {t("translation:market.bulk.cart.items")})
              </Text>
              <Link
                paddingTop={"3px"}
                as="button"
                type="button"
                fontWeight="medium"
                fontSize="md"
                color={"blue.500"}
                onClick={onClearCart}
              >
                {t("translation:market.bulk.cart.clear")}
              </Link>
            </HStack>
          </Heading>

          <Stack spacing={{ base: "8", md: "10" }}>
            {Array.from(globalState.shoppingCart.keys()).map(
              (card_id: string) => (
                <CartItem
                  key={parseInt(card_id)}
                  card_id={card_id}
                  mode={mode}
                  initialCount={globalState.shoppingCart.get(card_id)["count"]}
                />
              )
            )}
          </Stack>
        </Stack>
        <Stack
          borderTopWidth="1px"
          px={{ base: "6", md: "10" }}
          py="4"
          spacing="5"
        >
          <Stack>
            {errorMessage === "" ? null : (
              <Alert status="error">
                <AlertIcon />
                <AlertTitle mr={2}>{errorMessage}</AlertTitle>
                <CloseButton
                  onClick={() => {
                    setErrorMessage("");
                  }}
                  position="absolute"
                  right="8px"
                  top="8px"
                />
              </Alert>
            )}
            <HStack fontSize="xl" fontWeight="semibold">
              <Text flex="1">
                {t("translation:market.bulk.cart.subtotal")}({subtotalText}
                ):
              </Text>
              {total >= 0 ? <Text>{total.toFixed(2)}</Text> : <Spinner />}
            </HStack>
          </Stack>

          <button
            className={[
              styles.button,
              styles.buttonGradientBlue,
              styles.buttonGlow,
              styles.fullButton,
            ].join(" ")}
            onClick={onCheckout}
          >
            {checkoutText}
          </button>
        </Stack>
      </DrawerContent>
    </Drawer>
  );
}
