import {
  Container,
  Box,
  Button,
  Flex,
  Link,
  Text,
  SimpleGrid,
  GridItem,
  Checkbox,
  Spacer,
  useDisclosure,
  HStack,
  Select,
  Input,
  Spinner,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Image,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  IconButton,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuOptionGroup,
  MenuItemOption,
  MenuDivider,
  VStack,
} from "@chakra-ui/react";
import { Component, useEffect, useRef, useState } from "react";
import { BigNumber } from "@ethersproject/bignumber";
import { author_wallet, EXCHANGE_ADDRESS } from "../constant";
import React from "react";
import { useGlobalState } from "../../hooks/GlobalState";
import {
  sksSellPriceRate,
  sksFeeRate,
  sksBuyPriceRate,
  priceDecimals,
  skyweaverSellPriceRate,
  skyweaverBuyPriceRate,
} from "../../constants/exchange";
import ReactGA from "react-ga";
import ConnectButton from "../ConnectButton";
import { useTranslation } from "react-i18next";
import {
  CRYSTAL_LIST,
  GOLD_CARD_LIST,
  LIQUIDITY_CARD_LIST,
  SILVER_CARD_LIST,
} from "../../constants/cardList";
import CardV2 from "./CardV2";
import { useSearchParams } from "react-router-dom";
import {
  api_url,
  enableCardWithNoSupply,
  enableStar,
} from "../../constants/flags";
import styles from "../../css/web3.module.css";
import ShoppingCart from "./Cart/ShoppingCart";
import { FiShoppingBag, FiShoppingCart } from "react-icons/fi";
import { ChevronDownIcon } from "@chakra-ui/icons";
import ShoppingCartIcon from "./Cart/ShoppingCartIcon";
import { promises } from "fs";
import { formatUnits } from "ethers/lib/utils";
import { BulkButton } from "./BulkButton";
import { FilterButton } from "./FilterButton";

const LOAD_CHUNK = 12;

export default function LiquidityList() {
  const {
    isOpen: isCartOpen,
    onOpen: onCartOpen,
    onClose: onCartClose,
  } = useDisclosure();
  ReactGA.pageview(window.location.pathname);
  const { t, i18n } = useTranslation("translation");

  const ref = useRef();
  const atBottom = useOnScreen(ref, "300px");

  const [rarityTabIndex, setRarityTabIndex] = useState(1);
  const [ownershipTabIndex, setOwnershipTabIndex] = useState(0);
  const [starSet, setStarSet] = useState(new Set<string>());
  const [starAsset, setStarAsset] = useState({ goldValue: 0, silverValue: 0 });
  const [accountEnabled, setAccountEnabled] = useState(false);

  const textColor = "white";
  const [rankValue, setRankValue] = useState("PriceDown");
  const [globalState, dispatch] = useGlobalState();
  const [showCrystal, setShowCrystal] = useState(false);
  const [crystalList, setCrystalList] = useState([]);
  const [tradingMode, setTradingMode] = useState("normal");
  const [filterValue, setFilterValue] = useState(["Gold", "Silver"]);
  const [state, setState] = useState({
    silverCardValue: 0,
    silverCashValue: 0,
    silverOwnedCardValue: 0,
    silverCardCount: 0,
    silverCardPoolCount: 0,
    goldCardValue: 0,
    goldCashValue: 0,
    goldOwnedCardValue: 0,
    goldCardCount: 0,
    goldCardPoolCount: 0,
    cardValue: 0,
    cashValue: 0,
    ownedCardValue: 0,
    crystalValue: 0,
    cardList: [],
    filteredList: [],
    visibleList: [],
    royalty: 0,
    loading: true,
  });
  let isLoading = false;

  const [searchParams, setSearchParams] = useSearchParams();

  const showCardWithNoSupply =
    enableCardWithNoSupply || searchParams.get("nosupply") === "1";

  const showShoppingCart = !showCardWithNoSupply && globalState.account;

  useEffect(() => {
    const newState = { ...state };
    newState["visibleList"] = state["filteredList"].slice(
      0,
      state["visibleList"].length + LOAD_CHUNK
    );
    setState(newState);
  }, [atBottom]);

  useEffect(() => {
    if (globalState.account != null) {
      setAccountEnabled(true);
    }
    if (!isLoading) {
      loadContent();
    }
  }, [globalState.account]);

  useEffect(() => {
    if (globalState.provider === null) {
      return;
    }
    if (!isLoading) {
      // loadContent() but without self information, used for logout users
      loadProviderContent();
    }
  }, [globalState.provider]);

  useEffect(() => {
    loadFilter();
  }, [state]);

  useEffect(() => {
    const newState = { ...state };
    const cardList = newState["cardList"];
    newState["filteredList"] = filterList(cardList);
    newState["visibleList"] = newState["filteredList"].slice(0, LOAD_CHUNK);
    setState(newState);
  }, [rarityTabIndex, ownershipTabIndex, rankValue, starSet]);

  useEffect(() => {
    const cardList = state["cardList"];
    let goldValue = 0;
    let silverValue = 0;
    cardList.forEach((card: any) => {
      if (starSet.has(card["ID"])) {
        if (card["rarity"] == "gold") {
          goldValue +=
            card["self_ownedCardValue"] +
            card["pool_ownedCashValue"] +
            card["pool_ownedCardValue"];
        }
        if (card["rarity"] == "silver") {
          silverValue +=
            card["self_ownedCardValue"] +
            card["pool_ownedCashValue"] +
            card["pool_ownedCardValue"];
        }
      }
    });
    setStarAsset({
      goldValue: goldValue,
      silverValue: silverValue,
    });
  }, [starSet]);

  useEffect(() => {
    const filter = [];
    if (rarityTabIndex == 0) {
      filter.push("Silver");
    } else {
      filter.push("Gold");
    }
    setFilterValue(filter);
  }, [rarityTabIndex]);

  useEffect(() => {
    const newState = { ...state };
    const cardList = newState["cardList"];
    newState["filteredList"] = filterList(cardList);
    newState["visibleList"] = newState["filteredList"].slice(0, LOAD_CHUNK);
    setState(newState);
  }, [filterValue]);

  const filterList = (list: any[]) => {
    return rankList(
      list.filter((card: any) => {
        let show = true;
        if (ownershipTabIndex == 2) {
          show = show && card["self_cardOwned"] > 0;
        }
        if (ownershipTabIndex == 3) {
          show = show && card["pool_ownedPercentage"] > 0;
        }
        if (ownershipTabIndex == 4) {
          show = show && starSet.has(card["ID"]);
        }
        if (!show) return false;
        if (filterValue.includes("Silver") && card["rarity"] == "silver") {
          return true;
        }
        if (filterValue.includes("Gold") && card["rarity"] == "gold") {
          return true;
        }
        return false;
      }),
      rankValue
    );
  };

  const rankList = (list: any[], rankValue) => {
    return list.sort((a, b) => {
      // Empty string is the default PriceDown rank
      if (rankValue === "PriceDown" || rankValue === "") {
        return b["card_buyPrice"] - a["card_buyPrice"];
      }
      if (rankValue === "PriceUp") {
        return a["card_buyPrice"] - b["card_buyPrice"];
      }
      if (rankValue === "NameUp") {
        return a["name"] < b["name"] ? -1 : 1;
      }
      if (rankValue === "NameDown") {
        return a["name"] > b["name"] ? -1 : 1;
      }
      if (rankValue === "SupplyUp") {
        return a["supply"] < b["supply"] ? -1 : 1;
      }
      if (rankValue === "SupplyDown") {
        return a["supply"] > b["supply"] ? -1 : 1;
      }
    });
  };

  const loadContent = async () => {
    if (!globalState.account) {
      return [[], false];
    }
    isLoading = true;
    let royalty = 0;
    if (author_wallet === globalState.account) {
      royalty = await globalState.exchangeTradeContract.getRoyalties(
        author_wallet
      );
      royalty = royalty / priceDecimals;
    }

    let enrichCardList = async (
      cardList: any[],
      onlyWithSupply: boolean = true
    ) => {
      const BAN_LIST = ["131082", "134082", "135087"];
      cardList = cardList.filter((card: any) => !BAN_LIST.includes(card["ID"]));
      let cardIdList = cardList.map((card: any) => {
        return BigNumber.from(card["ID"]);
      });
      const cardMap = new Map(cardList.map((card) => [card["ID"], card]));

      var totalSupplyList =
        await globalState.exchangeTradeContract.getTotalSupply(cardIdList);
      if (onlyWithSupply) {
        cardIdList = cardIdList.filter(
          (cardId: any, index: number) => totalSupplyList[index] > 0
        );
      }
      console.log(cardList)
      totalSupplyList = totalSupplyList.filter(
        (totalSupply: any, index: number) => totalSupply > 0
      );

      const accountAddresstList = Array(cardIdList.length).fill(
        globalState.account
      );
      const exchangeAddresstList = Array(cardIdList.length).fill(
        EXCHANGE_ADDRESS
      );
      const cardAmountForPriceList = Array(cardIdList.length).fill(100);
      var balanceListPromise = globalState.exchangeTradeContract.balanceOfBatch(
        accountAddresstList,
        cardIdList
      );
      var currencyReservesListPromise =
        globalState.exchangeTradeContract.getCurrencyReserves(cardIdList);
      var cardBalanceListPromise = globalState.skyweaverQueryContract
        .balanceOfBatch(exchangeAddresstList, cardIdList)
        .catch((error) => {
          return Promise.resolve(Array(cardIdList.length).fill(0));
        });
      var selfCardBalanceListPromise = globalState.skyweaverQueryContract
        .balanceOfBatch(accountAddresstList, cardIdList)
        .catch((error) => {
          return Promise.resolve(Array(cardIdList.length).fill(0));
        });
      var cardSellPriceListPromise = globalState.exchangeTradeContract
        .getPrice_tokenToCurrency(cardIdList, cardAmountForPriceList)
        .catch((error) => {
          return Promise.resolve(Array(cardIdList.length).fill(0));
        });
      var cardBuyPriceListPromise = globalState.exchangeTradeContract
        .getPrice_currencyToToken(cardIdList, cardAmountForPriceList)
        .catch((error) => {
          return Promise.resolve(Array(cardIdList.length).fill(0));
        });

      var cardSupplyListPromise =
        globalState.skyweaverQueryContract.getCurrentIssuances(cardIdList);

      var [
        balanceList,
        currencyReservesList,
        cardBalanceList,
        selfCardBalanceList,
        cardSellPriceList,
        cardBuyPriceList,
        cardSupplyList,
      ] = await Promise.all([
        balanceListPromise,
        currencyReservesListPromise,
        cardBalanceListPromise,
        selfCardBalanceListPromise,
        cardSellPriceListPromise,
        cardBuyPriceListPromise,
        cardSupplyListPromise,
      ]);

      for (let i = 0; i < cardIdList.length; i++) {
        let card = cardMap.get(cardIdList[i].toNumber().toString());
        card["available"] = true;
        let balance = balanceList[i];
        card["pool_ownedShare"] = balance;

        let totalSupply = totalSupplyList[i];
        let currencyReserves = currencyReservesList[i];
        card["pool_ownedPercentage"] = balance / totalSupply;
        card["pool_cashReserves"] =
          Math.round((currencyReserves / priceDecimals) * 100) / 100;

        let cardBalance = cardBalanceList[i];
        card["pool_cardInPool"] = cardBalance / 100;

        var selfCardBalance = selfCardBalanceList[i];
        card["self_cardOwned"] = selfCardBalance / 100;

        var cardSellPrice = cardSellPriceList[i];
        card["card_sellPrice"] =
          Math.round(
            ((cardSellPrice * skyweaverSellPriceRate) / priceDecimals) * 100
          ) / 100;
        card["card_sksSellPrice"] =
          Math.floor(
            ((cardSellPrice * sksSellPriceRate) / priceDecimals) * 100
          ) / 100;
        if (card["rarity"] === "crystal") {
          card["card_sellPrice"] = card["card_sksSellPrice"];
        }
        card["card_sksSellFee"] = (cardSellPrice * sksFeeRate) / priceDecimals;

        var cardBuyPrice = cardBuyPriceList[i];
        card["card_buyPrice"] =
          Math.round(
            ((cardBuyPrice * skyweaverBuyPriceRate) / priceDecimals) * 100
          ) / 100;
        card["card_sksBuyPrice"] =
          Math.floor(((cardBuyPrice * sksBuyPriceRate) / priceDecimals) * 100) /
          100;
        card["card_sksBuyFee"] = (cardBuyPrice * sksFeeRate) / priceDecimals;

        card["pool_ownedCardInPool"] =
          Math.floor(
            card["pool_ownedPercentage"] * card["pool_cardInPool"] * 100
          ) / 100;
        card["pool_ownedCardValue"] =
          Math.round(
            card["pool_ownedCardInPool"] * card["card_sellPrice"] * 100
          ) / 100;
        card["pool_ownedCashValue"] =
          Math.round(
            card["pool_ownedPercentage"] * card["pool_cashReserves"] * 100
          ) / 100;
        card["pool_liquidityValue"] =
          card["pool_cardInPool"] == 0
            ? 0
            : Math.round(
                (card["pool_cashReserves"] / card["pool_cardInPool"]) * 100
              ) / 100;

        card["self_ownedCardValue"] =
          card["card_sellPrice"] * card["self_cardOwned"];
        if (!card["supply"]) {
          card["supply"] = Math.round(cardSupplyList[i].toNumber() / 100);
        }
      }
    };
    let goldCardList = GOLD_CARD_LIST;
    let silverCardList = SILVER_CARD_LIST;

    let crystalData = [];
    crystalData = CRYSTAL_LIST;
    let allCardList = [...goldCardList, ...silverCardList, ...crystalData];
    if (showCardWithNoSupply) {
      let liquidityCardList = LIQUIDITY_CARD_LIST;
      liquidityCardList.forEach((card: any) => {
        card["rarity"] = "crystal";
        card["available"] = true;
        card["self_ownedCardValue"] = 0;
        card["pool_ownedCashValue"] = 0;

        card["pool_ownedShare"] = 0;
        card["pool_ownedPercentage"] = 0;
        card["pool_cashReserves"] = 0;

        card["pool_cardInPool"] = 0;
        card["self_cardOwned"] = 100;

        card["card_sellPrice"] = 0;
        card["card_sksSellPrice"] = 0;
        card["card_sksSellFee"] = 0;

        card["card_buyPrice"] = 0;
        card["card_sksBuyPrice"] = 0;
        card["card_sksBuyFee"] = 0;

        card["pool_ownedCardInPool"] = 0;
        card["pool_ownedCardValue"] = 0;
        card["pool_ownedCashValue"] = 0;
        card["pool_liquidityValue"] = 0;

        card["self_ownedCardValue"] = 0;
        if (!card["supply"]) {
          card["supply"] = 0;
        }
      });
      allCardList = [...liquidityCardList];
    } else {
      await enrichCardList(allCardList);
    }
    allCardList = allCardList.filter((card: any) => card["available"]);
    goldCardList = allCardList.filter((card: any) => card["rarity"] === "gold");
    silverCardList = allCardList.filter(
      (card: any) => card["rarity"] === "silver"
    );
    crystalData = allCardList.filter(
      (card: any) => card["rarity"] === "crystal"
    );
    setCrystalList(crystalData);

    let cardValue = 0;
    let cashValue = 0;
    let ownedCardValue = 0;

    let silverCardCount = 0;
    let silverCardPoolCount = 0;
    let silverCardValue = 0;
    let silverCashValue = 0;
    let silverOwnedCardValue = 0;

    let goldCardCount = 0;
    let goldCardPoolCount = 0;
    let goldCardValue = 0;
    let goldCashValue = 0;
    let goldOwnedCardValue = 0;

    let crystalValue = 0;
    goldCardList.forEach((card: any) => {
      cardValue += card["pool_ownedCardValue"];
      cashValue += card["pool_ownedCashValue"];
      ownedCardValue += card["self_ownedCardValue"];

      goldCardValue += card["pool_ownedCardValue"];
      goldCashValue += card["pool_ownedCashValue"];
      goldOwnedCardValue += card["self_ownedCardValue"];
      goldCardCount += card["self_cardOwned"];
      goldCardPoolCount += card["pool_ownedCardInPool"];
    });
    silverCardList.forEach((card: any) => {
      cardValue += card["pool_ownedCardValue"];
      cashValue += card["pool_ownedCashValue"];
      ownedCardValue += card["self_ownedCardValue"];

      silverCardValue += card["pool_ownedCardValue"];
      silverCashValue += card["pool_ownedCashValue"];
      silverOwnedCardValue += card["self_ownedCardValue"];
      silverCardCount += card["self_cardOwned"];
      silverCardPoolCount += card["pool_ownedCardInPool"];
    });
    crystalData.forEach((card: any) => {
      crystalValue +=
        card["pool_ownedCardValue"] +
        card["pool_ownedCashValue"] +
        card["self_ownedCardValue"];
    });
    isLoading = false;
    const filteredList = filterList(allCardList);
    const newState = {
      silverCardValue: silverCardValue,
      silverCashValue: silverCashValue,
      silverOwnedCardValue: silverOwnedCardValue,
      silverCardCount: silverCardCount,
      silverCardPoolCount: silverCardPoolCount,
      goldCardValue: goldCardValue,
      goldCashValue: goldCashValue,
      goldOwnedCardValue: goldOwnedCardValue,
      goldCardCount: goldCardCount,
      goldCardPoolCount: goldCardPoolCount,
      cardValue: cardValue,
      cashValue: cashValue,
      ownedCardValue: ownedCardValue,
      crystalValue: crystalValue,
      cardList: allCardList,
      filteredList: filteredList,
      visibleList: filteredList.slice(0, LOAD_CHUNK),
      royalty: royalty,
      loading: false,
    };
    if (globalState.account) {
      loadStar();
    }
    if (JSON.stringify(state) !== JSON.stringify(newState)) {
      setState(newState);
    }
  };

  const loadProviderContent = async () => {
    if (globalState.account) {
      return;
    }
    let enrichCardList = async (cardList: any[]) => {
      const BAN_LIST = ["131082", "134082", "135087"];
      cardList = cardList.filter((card: any) => !BAN_LIST.includes(card["ID"]));
      let cardIdList = cardList.map((card: any) => {
        return BigNumber.from(card["ID"]);
      });
      const cardMap = new Map(cardList.map((card) => [card["ID"], card]));

      var totalSupplyList =
        await globalState.exchangeTradeContract.getTotalSupply(cardIdList);
      cardIdList = cardIdList.filter(
        (cardId: any, index: number) => totalSupplyList[index] > 0
      );
      cardIdList = cardIdList.filter(
        (cardId: any, index: number) =>
          cardId !== 131082 || cardId !== 134082 || cardId !== 135087
      );
      totalSupplyList = totalSupplyList.filter(
        (totalSupply: any, index: number) => totalSupply > 0
      );

      const exchangeAddresstList = Array(cardIdList.length).fill(
        EXCHANGE_ADDRESS
      );
      const cardAmountForPriceList = Array(cardIdList.length).fill(100);

      var currencyReservesListPromise =
        globalState.exchangeTradeContract.getCurrencyReserves(cardIdList);
      var cardBalanceListPromise = globalState.skyweaverQueryContract
        .balanceOfBatch(exchangeAddresstList, cardIdList)
        .catch((error) => {
          return Promise.resolve(Array(cardIdList.length).fill(0));
        });
      var cardSellPriceListPromise = globalState.exchangeTradeContract
        .getPrice_tokenToCurrency(cardIdList, cardAmountForPriceList)
        .catch((error) => {
          return Promise.resolve(Array(cardIdList.length).fill(0));
        });
      var cardBuyPriceListPromise = globalState.exchangeTradeContract
        .getPrice_currencyToToken(cardIdList, cardAmountForPriceList)
        .catch((error) => {
          return Promise.resolve(Array(cardIdList.length).fill(0));
        });
      var cardSupplyListPromise =
        globalState.skyweaverQueryContract.getCurrentIssuances(cardIdList);

      var [
        currencyReservesList,
        cardBalanceList,
        cardSellPriceList,
        cardBuyPriceList,
        cardSupplyList,
      ] = await Promise.all([
        currencyReservesListPromise,
        cardBalanceListPromise,
        cardSellPriceListPromise,
        cardBuyPriceListPromise,
        cardSupplyListPromise,
      ]);

      for (let i = 0; i < cardIdList.length; i++) {
        let card = cardMap.get(cardIdList[i].toNumber().toString());
        card["available"] = true;

        card["self_ownedCardValue"] = 0;
        card["pool_ownedCashValue"] = 0;

        card["pool_ownedShare"] = 0;
        card["pool_ownedPercentage"] = 0;

        card["self_cardOwned"] = 0;

        card["pool_ownedCardInPool"] = 0;
        card["pool_ownedCardValue"] = 0;
        card["pool_ownedCashValue"] = 0;

        let currencyReserves = currencyReservesList[i];
        card["pool_cashReserves"] =
          Math.round((currencyReserves / 1000000) * 100) / 100;

        let cardBalance = cardBalanceList[i];
        card["pool_cardInPool"] = cardBalance / 100;

        var cardSellPrice = cardSellPriceList[i];
        card["card_sellPrice"] =
          Math.round(
            ((cardSellPrice * skyweaverSellPriceRate) / 1000000) * 100
          ) / 100;
        card["card_sksSellPrice"] =
          Math.floor(((cardSellPrice * sksSellPriceRate) / 1000000) * 100) /
          100;
        if (card["rarity"] === "crystal") {
          card["card_sellPrice"] = card["card_sksSellPrice"];
        }
        card["card_sksSellFee"] = (cardSellPrice * sksFeeRate) / 1000000;

        var cardBuyPrice = cardBuyPriceList[i];
        card["card_buyPrice"] =
          Math.round(((cardBuyPrice * skyweaverBuyPriceRate) / 1000000) * 100) /
          100;
        card["card_sksBuyPrice"] =
          Math.floor(((cardBuyPrice * sksBuyPriceRate) / 1000000) * 100) / 100;
        card["card_sksBuyFee"] = (cardBuyPrice * sksFeeRate) / 1000000;

        card["pool_ownedCardInPool"] =
          Math.floor(
            card["pool_ownedPercentage"] * card["pool_cardInPool"] * 100
          ) / 100;
        card["pool_ownedCardValue"] =
          Math.round(
            card["pool_ownedCardInPool"] * card["card_sellPrice"] * 100
          ) / 100;
        card["pool_ownedCashValue"] =
          Math.round(
            card["pool_ownedPercentage"] * card["pool_cashReserves"] * 100
          ) / 100;
        card["pool_liquidityValue"] =
          card["pool_cardInPool"] == 0
            ? 0
            : Math.round(
                (card["pool_cashReserves"] / card["pool_cardInPool"]) * 100
              ) / 100;

        card["self_ownedCardValue"] =
          card["card_sellPrice"] * card["self_cardOwned"];
        if (!card["supply"]) {
          card["supply"] = Math.round(cardSupplyList[i].toNumber() / 100);
        }
      }
    };
    let goldCardList = GOLD_CARD_LIST;
    let silverCardList = SILVER_CARD_LIST;
    let crystalData = [];
    crystalData = CRYSTAL_LIST;
    let allCardList = [...goldCardList, ...silverCardList, ...crystalData];
    await enrichCardList(allCardList);
    allCardList = allCardList.filter((card: any) => card["available"]);
    crystalData = allCardList.filter(
      (card: any) => card["rarity"] === "crystal"
    );
    setCrystalList(crystalData);

    isLoading = false;
    const filteredList = filterList(allCardList);
    const newState = {
      ...state,
      cardList: allCardList,
      filteredList: filteredList,
      visibleList: filteredList.slice(0, LOAD_CHUNK),
      loading: false,
    };
    if (JSON.stringify(state) !== JSON.stringify(newState)) {
      setState(newState);
    }
  };
  const loadStar = async () => {
    if (globalState.account != null) {
      fetch(api_url + "/getlikes?address=" + globalState.account)
        .then((response) => response.json())
        .then((response) => {
          const list = response["data"].map((item) =>
            item["card_id"].toString()
          );
          const newSet = new Set<string>(list);
          setStarSet(newSet);
        });
    }
  };

  const handleCardStar = (card_id, isStarred) => {
    if (globalState.account != null) {
      if (isStarred) {
        setStarSet(new Set(starSet).add(card_id));
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            address: globalState.account,
            card_id: card_id,
          }),
        };
        fetch(api_url + "/addlike", requestOptions).then((response) =>
          response.json()
        );
      } else {
        const newSet = new Set(starSet);
        newSet.delete(card_id);
        setStarSet(newSet);
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            address: globalState.account,
            card_id: card_id,
          }),
        };
        fetch(api_url + "/removelike", requestOptions).then((response) =>
          response.json()
        );
      }
    }
  };

  const handleRankChange = (value) => {
    localStorage.setItem("rankValue", value);
    setRankValue(value);
  };

  const handleSearchChange = (event) => {
    const searchText = event.target.value;
    ReactGA.event({
      category: "Filter",
      action: "Search Text Change",
      label: searchText,
    });
    const newState = { ...state };
    const cardList = newState["cardList"];
    newState["filteredList"] = filterList(cardList).filter((card: any) =>
      card["name"].toLowerCase().includes(searchText.toLowerCase())
    );
    newState["visibleList"] = newState["filteredList"].slice(0, LOAD_CHUNK);
    setState(newState);
  };

  const withdrawRoyalty = async () => {
    await globalState.exchangeTradeContract.sendRoyalties(author_wallet);
  };

  // Hook
  function useOnScreen(ref, rootMargin = "0px") {
    // State and setter for storing whether element is visible
    const [isIntersecting, setIntersecting] = useState(false);
    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          // Update our state when observer callback fires
          setIntersecting(entry.isIntersecting);
        },
        {
          rootMargin,
        }
      );
      if (ref.current) {
        observer.observe(ref.current);
      }
      return () => {
        observer.unobserve(ref.current);
      };
    }, []); // Empty array ensures that effect is only run on mount and unmount
    return isIntersecting;
  }

  const handleRarityTabsChange = (index) => {
    const newState = { ...state };
    const cardList = newState["cardList"];
    newState["filteredList"] = filterList(cardList);
    newState["visibleList"] = newState["filteredList"].slice(0, LOAD_CHUNK);
    setRarityTabIndex(index);
    localStorage.setItem("rarityTabIndex", index);
    setState(newState);
  };

  const handleOwnershipTabsChange = (index) => {
    setOwnershipTabIndex(index);
    localStorage.setItem("ownershipTabIndex", index);
    if (index == 0) {
      setShowCrystal(false);
    }
    if (index == 1) {
      setShowCrystal(true);
    }
    if (index == 2) {
      setShowCrystal(false);
    }
    if (index == 3) {
      setShowCrystal(false);
      return;
    }
    if (index == 4) {
      setShowCrystal(false);
      return;
    }
  };

  const handleBulkModeChange = (mode: string) => {
    setTradingMode(mode);
  };

  const reload = async () => {
    const newState = { ...state };
    newState["loading"] = true;
    // A hack to get around a weird issue on reload.
    state["loading"] = true;
    await setState(newState);
    const loadBalance = globalState.usdcContract.balanceOf(globalState.account);
    await Promise.all([loadBalance, loadContent()]).then(([balance, _]) => {
      dispatch({ balance: parseFloat(formatUnits(balance, 6)).toFixed(2) });
    });
  };

  const content = showCrystal ? (
    <SimpleGrid
      columns={{ base: 1, md: 2, lg: 3, xl: 4, xxl: 5 }}
      spacing={8}
      maxW="1920px"
      marginTop={"3px"}
    >
      {crystalList.map((card: any, i: any) => (
        <CardV2
          card={card}
          key={parseInt(card["ID"])}
          textColor={textColor}
          showLiquidityDetail={state["cashValue"] + state["crystalValue"] > 0}
          mode={tradingMode}
          refreshCallback={reload}
        ></CardV2>
      ))}
    </SimpleGrid>
  ) : (
    <SimpleGrid
      columns={{ base: 1, md: 2, lg: 3, xl: 4, xxl: 5 }}
      spacing={8}
      maxW="1920px"
      marginTop={"3px"}
    >
      {state["visibleList"].map((card: any, i: any) => (
        <CardV2
          card={card}
          key={parseInt(card["ID"])}
          textColor={textColor}
          showLiquidityDetail={state["cashValue"] + state["crystalValue"] > 0}
          mode={tradingMode}
          refreshCallback={reload}
          star={starSet.has(card["ID"])}
          onStarCallback={handleCardStar}
          enableStar={accountEnabled}
        ></CardV2>
      ))}
    </SimpleGrid>
  );

  const assetHeader = (
    <Accordion
      allowToggle
      onChange={(e) => {
        if (e === 0) {
          ReactGA.event({
            category: "Activity",
            action: "Open total asset",
          });
        } else {
          ReactGA.event({
            category: "Activity",
            action: "Close total asset",
          });
        }
      }}
    >
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left" color={textColor}>
              <HStack>
                <Text color={textColor}>{t("account.totalAsset")}:</Text>
                <Image src={"/icon/usdc.png"} w={4} />
                <Text color={textColor}>
                  {(
                    Math.floor(
                      (state["cardValue"] +
                        state["cashValue"] +
                        state["ownedCardValue"] +
                        parseFloat(globalState.balance) +
                        state["crystalValue"]) *
                        100
                    ) / 100
                  ).toFixed(2)}
                </Text>
                <Text color={textColor}>(</Text>
                <Image w={"20px"} src="/icon/gold-card-with-letter.png" />
                <Text color={textColor}>:</Text>
                <Text color={textColor}>
                  {parseFloat(state["goldCardCount"].toFixed(2))}
                </Text>
                <Image w={"20px"} src="/icon/silver-card-with-letter.png" />
                <Text color={textColor}>:</Text>
                <Text color={textColor}>
                  {parseFloat(state["silverCardCount"].toFixed(2))}
                </Text>
                <Text color={textColor}>)</Text>
              </HStack>
            </Box>
            <AccordionIcon color={textColor} />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <HStack>
            <Text color={textColor}>{t("account.cashAsset")}: </Text>
            <Image src={"/icon/usdc.png"} w={4} />
            <Text color={textColor}>{globalState.balance}</Text>
          </HStack>
          <HStack>
            <Text color={textColor}>{t("account.cardAsset")}: </Text>
            <Image src={"/icon/usdc.png"} w={4} />
            <Text color={textColor}>
              {Math.floor(state["ownedCardValue"] * 100) / 100}
            </Text>
            <HStack display={{ base: "none", md: "flex" }}>
              <Text color={textColor}>(</Text>
              <Image w={"20px"} src="/icon/gold-card-with-letter.png" />
              <Text color={textColor}>:</Text>
              <Image src={"/icon/usdc.png"} w={4} />
              <Text color={textColor}>
                {Math.floor(state["goldOwnedCardValue"] * 100) / 100}
              </Text>
              <Image w={"20px"} src="/icon/silver-card-with-letter.png" />
              <Text color={textColor}>:</Text>
              <Image src={"/icon/usdc.png"} w={4} />
              <Text color={textColor}>
                {Math.floor(state["silverOwnedCardValue"] * 100) / 100}
              </Text>
              <Text color={textColor}>)</Text>
            </HStack>
          </HStack>
          {state["cardValue"] > 0 ? <Spacer h={"10px"} /> : null}
          {state["cardValue"] > 0 ? (
            <HStack>
              <Text color={textColor}>{t("account.liquidityCardCount")}: </Text>
              <HStack display={{ base: "none", md: "flex" }}>
                <Image w={"20px"} src="/icon/gold-card-with-letter.png" />
                <Text color={textColor}>:</Text>
                <Text color={textColor}>
                  {Math.floor(state["goldCardPoolCount"] * 100) / 100}
                </Text>
                <Image w={"20px"} src="/icon/silver-card-with-letter.png" />
                <Text color={textColor}>:</Text>
                <Text color={textColor}>
                  {Math.floor(state["silverCardPoolCount"] * 100) / 100}
                </Text>
              </HStack>
            </HStack>
          ) : null}
          {state["cardValue"] > 0 ? (
            <HStack>
              <Text color={textColor}>{t("account.liquidityCard")}: </Text>
              <Image src={"/icon/usdc.png"} w={4} />
              <Text color={textColor}>
                {Math.floor(state["cardValue"] * 100) / 100}
              </Text>
              <HStack display={{ base: "none", md: "flex" }}>
                <Text color={textColor}>(</Text>
                <Image w={"20px"} src="/icon/gold-card-with-letter.png" />
                <Text color={textColor}>:</Text>
                <Image src={"/icon/usdc.png"} w={4} />
                <Text color={textColor}>
                  {Math.floor(state["goldCardValue"] * 100) / 100}
                </Text>
                <Image w={"20px"} src="/icon/silver-card-with-letter.png" />
                <Text color={textColor}>:</Text>
                <Image src={"/icon/usdc.png"} w={4} />
                <Text color={textColor}>
                  {Math.floor(state["silverCardValue"] * 100) / 100}
                </Text>
                <Text color={textColor}>)</Text>
              </HStack>
            </HStack>
          ) : null}
          {state["cashValue"] > 0 ? (
            <HStack>
              <Text color={textColor}>{t("account.liquidityCash")}: </Text>
              <Image src={"/icon/usdc.png"} w={4} />
              <Text color={textColor}>
                {Math.floor(state["cashValue"] * 100) / 100}
              </Text>
              <HStack display={{ base: "none", md: "flex" }}>
                <Text color={textColor}>(</Text>
                <Image w={"20px"} src="/icon/gold-card-with-letter.png" />
                <Text color={textColor}>:</Text>
                <Image src={"/icon/usdc.png"} w={4} />
                <Text color={textColor}>
                  {Math.floor(state["goldCashValue"] * 100) / 100}
                </Text>
                <Image w={"20px"} src="/icon/silver-card-with-letter.png" />
                <Text color={textColor}>:</Text>
                <Image src={"/icon/usdc.png"} w={4} />
                <Text color={textColor}>
                  {Math.floor(state["silverCashValue"] * 100) / 100}
                </Text>
                <Text color={textColor}>)</Text>
              </HStack>
            </HStack>
          ) : null}
          {starAsset["goldValue"] + starAsset["silverValue"] > 0 ||
          state["crystalValue"] > 0 ? (
            <Spacer h={"10px"} />
          ) : null}
          {starAsset["goldValue"] + starAsset["silverValue"] > 0 ? (
            <HStack>
              <Text color={textColor}>
                {t("translation:market.filter.starred")}:{" "}
              </Text>
              <Image src={"/icon/usdc.png"} w={4} />
              <Text color={textColor}>
                {Math.floor(
                  (starAsset["goldValue"] + starAsset["silverValue"]) * 100
                ) / 100}
              </Text>
              <HStack display={{ base: "none", md: "flex" }}>
                <Text color={textColor}>(</Text>
                <Image w={"20px"} src="/icon/gold-card-with-letter.png" />
                <Text color={textColor}>:</Text>
                <Image src={"/icon/usdc.png"} w={4} />
                <Text color={textColor}>
                  {Math.floor(starAsset["goldValue"] * 100) / 100}
                </Text>
                <Image w={"20px"} src="/icon/silver-card-with-letter.png" />
                <Text color={textColor}>:</Text>
                <Image src={"/icon/usdc.png"} w={4} />
                <Text color={textColor}>
                  {Math.floor(starAsset["silverValue"] * 100) / 100}
                </Text>
                <Text color={textColor}>)</Text>
              </HStack>
            </HStack>
          ) : null}
          {state["crystalValue"] > 0 ? (
            <HStack>
              <Text color={textColor}>{t("account.crystalAsset")}: </Text>
              <Image src={"/icon/usdc.png"} w={4} />
              <Text color={textColor}>{state["crystalValue"].toFixed(2)}</Text>
            </HStack>
          ) : null}
          {globalState.account == author_wallet ? (
            <HStack>
              <Text color={textColor}>
                Your royalty: {Math.floor(state["royalty"] * 100) / 100} USDC
              </Text>
              <Button size="sm" onClick={withdrawRoyalty}>
                Withdraw
              </Button>
              <Spacer />
            </HStack>
          ) : null}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );

  const ownershipTabList = (
    <TabList>
      <Tab _selected={{ color: "white", bg: "blue.500" }}>
        {t("translation:market.filter.all")}
      </Tab>
      <Tab _selected={{ color: "white", bg: "blue.500" }}>
        {t("translation:market.filter.crystal")}
      </Tab>
      {accountEnabled ? (
        <Tab _selected={{ color: "white", bg: "blue.500" }}>
          {t("translation:market.filter.owned")}
        </Tab>
      ) : null}
      {accountEnabled ? (
        <Tab _selected={{ color: "white", bg: "blue.500" }}>
          {t("translation:market.filter.liquidity")}
        </Tab>
      ) : null}
      {accountEnabled ? (
        <Tab _selected={{ color: "white", bg: "blue.500" }}>
          {t("translation:market.filter.starred")}
        </Tab>
      ) : null}
    </TabList>
  );

  const ownershipTabListMobile = (
    <TabList>
      <Tab _selected={{ color: "white", bg: "blue.500" }}>
        {t("translation:market.filter.all")}
      </Tab>
      <Tab _selected={{ color: "white", bg: "blue.500" }}>
        {t("translation:market.filter.crystal")}
      </Tab>
      <Tab _selected={{ color: "white", bg: "blue.500" }}>
        {t("translation:market.filter.ownedMobile")}
      </Tab>
      <Tab _selected={{ color: "white", bg: "blue.500" }}>
        {t("translation:market.filter.liquidityMobile")}
      </Tab>
    </TabList>
  );

  const filterHeader = (
    <Box>
      <HStack
        display={{ base: "flex", md: "none" }}
        paddingBottom={"5px"}
        paddingTop={"5px"}
      >
        <Spacer />
        <Tabs
          variant="unstyled"
          index={ownershipTabIndex}
          onChange={(index: number) => {
            let tabText;
            switch (index) {
              case 0:
                tabText = "All";
                break;
              case 1:
                tabText = "Owned";
                break;
              case 2:
                tabText = "Liquidity";
                break;
              case 3:
                tabText = "Crystal";
                break;
              case 4:
                tabText = "Starred";
                break;
            }
            ReactGA.event({
              category: "Filter",
              action: "Filter Tab Change",
              label: tabText,
            });
            handleOwnershipTabsChange(index);
          }}
          color={textColor}
        >
          {ownershipTabListMobile}
        </Tabs>
        <Spacer />
      </HStack>
      <HStack
        display={{ base: "flex", md: "none" }}
        paddingBottom={"5px"}
        paddingTop={"5px"}
      >
        <Spacer />
        <Box>
          {showShoppingCart ? (
            <BulkButton
              tradingMode={tradingMode}
              handleBulkModeChange={handleBulkModeChange}
            />
          ) : null}
        </Box>
        <FilterButton
          rankValue={rankValue}
          filterValue={filterValue}
          onRankChange={(value) => {
            ReactGA.event({
              category: "Filter",
              action: "Ranking Change",
              label: value,
            });
            handleRankChange(value);
          }}
          onFilterChange={(value) => {
            setFilterValue(value);
          }}
        />
        <Input
          color={textColor}
          placeholder="Search"
          w={"100px"}
          size="sm"
          onChange={handleSearchChange}
        />
        <Spacer />
      </HStack>
      <HStack
        paddingBottom={"5px"}
        paddingTop={"5px"}
        display={{ base: "none", md: "flex" }}
      >
        <Tabs
          variant="unstyled"
          w="s"
          index={ownershipTabIndex}
          onChange={handleOwnershipTabsChange}
          color={textColor}
        >
          {ownershipTabList}
        </Tabs>
        <Spacer />
        <Tabs
          variant="unstyled"
          index={rarityTabIndex}
          onChange={(index: number) => {
            ReactGA.event({
              category: "Filter",
              action: "Rarity Change",
              label: index === 1 ? "Gold" : "Silver",
            });
            handleRarityTabsChange(index);
          }}
          color={textColor}
          marginLeft={"0 !important"}
        >
          <TabList>
            <Tab _selected={{ color: "white", bg: "blue.500" }}>
              <Image w={"20px"} src="/icon/silver-card-with-letter.png" />
            </Tab>
            <Tab _selected={{ color: "white", bg: "blue.500" }}>
              <Image w={"20px"} src="/icon/gold-card-with-letter.png" />
            </Tab>
          </TabList>
        </Tabs>
        <Select
          onChange={(e) => {
            const value = e.target.value;
            ReactGA.event({
              category: "Filter",
              action: "Ranking Change",
              label: value,
            });
            handleRankChange(e.target.value);
          }}
          value={rankValue}
          w="m"
          textColor={textColor}
          iconColor={textColor}
          size="sm"
          className={styles.option}
        >
          <option className={styles.option} value="PriceDown">
            {t("translation:market.filter.price")} ↓↓
          </option>
          <option className={styles.option} value="PriceUp">
            {t("translation:market.filter.price")} ↑↑
          </option>
          <option className={styles.option} value="SupplyDown">
            {t("translation:market.filter.supply")} ↓↓
          </option>
          <option className={styles.option} value="SupplyUp">
            {t("translation:market.filter.supply")} ↑↑
          </option>
        </Select>
        <Box>
          {showShoppingCart ? (
            <BulkButton
              tradingMode={tradingMode}
              handleBulkModeChange={handleBulkModeChange}
            />
          ) : null}
        </Box>
        <Input
          color={textColor}
          placeholder="Search"
          w={"120px"}
          size="sm"
          onChange={handleSearchChange}
        />
        <Spacer display={{ base: "block", md: "none" }} />
      </HStack>
    </Box>
  );

  const loadFilter = async () => {
    const ownershipTabIndexStorage = localStorage.getItem("ownershipTabIndex");
    if (ownershipTabIndexStorage) {
      const index = parseInt(ownershipTabIndexStorage);
      if (index !== ownershipTabIndex) {
        await handleOwnershipTabsChange(index);
      }
    }
    const rarityTabIndexStorage = localStorage.getItem("rarityTabIndex");
    if (rarityTabIndexStorage) {
      const index = parseInt(rarityTabIndexStorage);
      if (index !== rarityTabIndex) {
        await handleRarityTabsChange(index);
      }
    }
    const rankValueStorage = localStorage.getItem("rankValue");
    if (rankValueStorage) {
      const value = rankValueStorage;
      if (value !== rankValue) {
        await handleRankChange(value);
      }
    }
  };

  return (
    <React.Fragment>
      {showShoppingCart ? (
        <ShoppingCart
          isOpen={isCartOpen}
          onOpen={onCartOpen}
          onClose={onCartClose}
          mode={tradingMode}
          succeedCallback={reload}
        />
      ) : null}
      {showShoppingCart && tradingMode.startsWith("bulk") ? (
        <ShoppingCartIcon onCartOpen={onCartOpen} mode={tradingMode} />
      ) : null}
      <Container
        bg="blackAlpha.900"
        maxW="100%"
        align="center"
        minH="90vh"
        paddingBottom={"10"}
      >
        <Box h={"100%"} display={state["loading"] ? "block" : "none"}>
          <Spacer />
          <Spinner color={textColor} size="xl"></Spinner>
          <Spacer />
        </Box>
        <Container
          paddingLeft={"4px"}
          paddingRight={"4px"}
          maxW="1920px"
          display={!state["loading"] ? "block" : "none"}
        >
          {globalState.account ? assetHeader : null}
          {filterHeader}
          {content}
          <div ref={ref}></div>
        </Container>
      </Container>
    </React.Fragment>
  );
}
