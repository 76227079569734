import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";
import Header from "./components/Header";
import ConnectButton from "./components/ConnectButton";
import AccountModal from "./components/AccountModal";
import LiquidityList from "./components/Market/LiquidityList";
import "@fontsource/inter";
import Footer from "./components/Footer";
import SimpleSidebar from "./components/Sidebar";
import { Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Banner from "./components/Banner";
import { GlobalStateProvider } from "./hooks/GlobalState";
import ReactGA from "react-ga";
import GuideIndex from "./components/Guide/GuideIndex";
import TradeGuide from "./components/Guide/TradeGuide";
import LiquidityGuide from "./components/Guide/LiquidityGuide";
import LiquidityManagementGuide from "./components/Guide/LiquidityManagementGuide";
import FAQ from "./components/Guide/FAQ";
import DataHome from "./components/Data/DataHome";
import ImpermanentLossGuide from "./components/Guide/ImpermanentLossGuide";
import LanguageDetector from "i18next-browser-languagedetector";

import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

import translation_cn from "./translations/cn/translation.json";
import translation_en from "./translations/en/translation.json";

i18next.use(LanguageDetector).init({
  interpolation: { escapeValue: false }, // React already does escaping
  react: {
    useSuspense: false, //   <---- this will do the magic
  },
  fallbackLng: "en",
  load: "languageOnly",
  resources: {
    en: {
      translation: translation_en,
    },
    zh: {
      translation: translation_cn,
    },
  },
});

function App() {
  ReactGA.initialize("UA-217694653-1", {
    debug: !process.env.NODE_ENV || process.env.NODE_ENV === "development",
  });
  //*** call without param to use language detector
  i18next.changeLanguage();

  return (
    <I18nextProvider i18n={i18next}>
      <GlobalStateProvider>
        <ChakraProvider theme={theme}>
          <Banner />
          <Header />
          <SimpleSidebar>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/Market" element={<LiquidityList />} />
              <Route path="/Guide" element={<GuideIndex />} />
              <Route
                path="/Guide/LiquidityGuide"
                element={<LiquidityGuide />}
              />
              <Route path="/Guide/TradeGuide" element={<TradeGuide />} />
              <Route path="/Guide/FAQ" element={<FAQ />} />
              <Route path="/Data" element={<DataHome />} />
              <Route
                path="/Guide/LiquidityManagementGuide"
                element={<LiquidityManagementGuide />}
              />
              <Route
                path="/Guide/ImpermanentLossGuide"
                element={<ImpermanentLossGuide />}
              />
            </Routes>
          </SimpleSidebar>
        </ChakraProvider>
      </GlobalStateProvider>
    </I18nextProvider>
  );
}

export default App;
