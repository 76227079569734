import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import { sequence } from "0xsequence";

export const providerOptions = {
  // walletconnect: {
  //   display: {
  //     name: "WalletConnect",
  //   },
  //   package: WalletConnectProvider,
  //   options: {
  //     pollingInterval: 1500000,
  //     infuraId: "bd5f506a6f39483a82641077572ca88e",
  //     rpc: {
  //       137: "https://polygon-mainnet.infura.io/v3/bd5f506a6f39483a82641077572ca88e",
  //     },
  //   },
  // },
  "custom-sequence": {
    display: {
      name: "Sequence",
      logo: "https://skyweaverswap.com/img/sequence-logo.png",
    },
    package: sequence,
    connector: async (ProviderPackage, options) => {
      let provider;
      if (window?.ethereum?.isSequence) {
        provider = window.ethereum;
        try {
          await provider.request({ method: "eth_requestAccounts" });
          return provider;
        } catch (error) {
          throw new Error("User Rejected");
        }
      }

      const wallet = sequence.initWallet({ defaultNetwork: 'polygon', projectAccessKey: 'e7takz1SHwsbvUN7ifZIyTLGAAAAAAAAA' })


      if (!wallet.isConnected()) {
        const connectDetails = await wallet.connect({
          app: "SkyweaverSwap",
          authorize: true,
        });

        if (!connectDetails.connected) {
          throw new Error("Failed to connect");
        }
      }

      provider = wallet.getProvider();
      provider.sequence = wallet;

      return provider;
    },
  },
  "custom-walletconnect": {
    display: {
      name: "WalletConnect",
      logo: "https://skyweaverswap.com/img/wallet-connect-logo.png",
    },
    package: WalletConnectProvider,
    options: {
      pollingInterval: 1500000,
      infuraId: "bd5f506a6f39483a82641077572ca88e",
      rpc: {
        137: "https://polygon-mainnet.infura.io/v3/bd5f506a6f39483a82641077572ca88e",
      },
    },
    connector: async (ProviderPackage, options) => {
      let bridge = "https://bridge.walletconnect.org";
      let qrcode = true;
      let infuraId = "";
      let rpc = undefined;
      let chainId = 1;
      let pollingInterval = 1500000;
      let qrcodeModalOptions = undefined;

      if (options) {
        bridge = options.bridge || bridge;
        qrcode =
          typeof options.qrcode !== "undefined" ? options.qrcode : qrcode;
        infuraId = options.infuraId || "";
        rpc = options.rpc || undefined;
        chainId = 137; // MATIC
        qrcodeModalOptions = options.qrcodeModalOptions || undefined;
        pollingInterval = options.pollingInterval || 1500000;
      }

      const provider = new ProviderPackage({
        bridge,
        qrcode,
        infuraId,
        rpc,
        chainId,
        pollingInterval,
        qrcodeModalOptions,
      });

      await provider.enable();

      return provider;
    },
  },
};

export const web3Modal = new Web3Modal({
  network: "matic", // optional
  cacheProvider: true, // optional
  providerOptions, // required
});
