import {
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import styles from "../../css/web3.module.css";
import { useGlobalState } from "../../hooks/GlobalState";
const MENU_WIDTH = "150px";

export function FilterButton({
  rankValue,
  onRankChange,
  filterValue,
  onFilterChange,
}) {
  const { t, i18n } = useTranslation("translation");

  return (
    <Menu closeOnSelect={false}>
      <MenuButton
        as={Button}
        height="33px"
        width="110px"
        fontSize={"sm"}
        className={[
          styles.button,
          styles.buttonGradientBlue,
          styles.buttonGlow,
          styles.ultraThinButton,
        ].join(" ")}
      >
        {t("translation:market.action.filter")}
      </MenuButton>
      <MenuList minWidth="240px">
        <MenuOptionGroup
          value={rankValue}
          title={t("translation:market.filter.order")}
          type="radio"
          onChange={onRankChange}
        >
          <MenuItemOption value="PriceDown">
            {t("translation:market.filter.price")} ↓↓
          </MenuItemOption>
          <MenuItemOption value="PriceUp">
            {t("translation:market.filter.price")} ↑↑
          </MenuItemOption>
          <MenuItemOption value="SupplyDown">
            {t("translation:market.filter.supply")} ↓↓
          </MenuItemOption>
          <MenuItemOption value="SupplyUp">
            {t("translation:market.filter.supply")} ↑↑
          </MenuItemOption>
        </MenuOptionGroup>
        <MenuDivider />
        <MenuOptionGroup
          title={t("translation:market.filter.type")}
          type="checkbox"
          value={filterValue}
          onChange={(e) => {
            onFilterChange(e);
          }}
        >
          <MenuItemOption value="Gold">
            {t("translation:market.filter.gold")}
          </MenuItemOption>
          <MenuItemOption value="Silver">
            {t("translation:market.filter.silver")}
          </MenuItemOption>
          {/* <MenuItemOption value="Crystal">
            {t("translation:market.filter.crystal")}
          </MenuItemOption> */}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
}
