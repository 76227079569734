import { Container, Box, Heading, Text, Image, Link } from "@chakra-ui/react";
import React from "react";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import Footer from "../Footer";

export default function GuideIndex() {
  ReactGA.pageview(window.location.pathname);
  const { t, i18n } = useTranslation("translation");
  return (
    <React.Fragment>
      <Container bg="blackAlpha.900" maxW={"7xl"} textColor={"white"}>
        <Container maxW={"7xl"} p="5">
          <Box
            marginTop={{ base: "1", md: "5" }}
            display="flex"
            flexDirection={{ base: "column", md: "row" }}
            justifyContent="space-between"
          >
            <Box
              display="flex"
              flex="1"
              marginRight="3"
              position="relative"
              alignItems="center"
            >
              <Box
                width={{ base: "100%", md: "85%" }}
                zIndex="2"
                marginLeft={{ base: "0", md: "5%" }}
                marginTop="5%"
              >
                <Link
                  href={"/Guide/FAQ"}
                  textDecoration="none"
                  _hover={{ textDecoration: "underline" }}
                >
                  <Image
                    borderRadius="lg"
                    src={
                      "https://www.skyweaver.net/images/media/wallpapers/wallpaper9.jpg"
                    }
                    objectFit="contain"
                  />
                </Link>
              </Box>
              <Box zIndex="1" width="100%" position="absolute" height="100%">
                <Box
                  bgGradient={"radial(orange.600 1px, transparent 1px)"}
                  backgroundSize="20px 20px"
                  opacity="0.4"
                  height="100%"
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flex="1"
              flexDirection="column"
              justifyContent="center"
              marginTop={{ base: "3", md: "0" }}
            >
              <Heading marginTop="1">
                <Link
                  href={"/Guide/FAQ"}
                  textDecoration="none"
                  _hover={{ textDecoration: "underline" }}
                >
                  {t("translation:guide.index.faqTitle")}
                </Link>
              </Heading>
              <Text as="p" marginTop="2" color={"white.800"} fontSize="lg">
                {t("translation:guide.index.faqIntro")}
              </Text>
            </Box>
          </Box>
        </Container>
        <Container maxW={"7xl"} p="5">
          <Box
            marginTop={{ base: "1", md: "5" }}
            display="flex"
            flexDirection={{ base: "column", md: "row" }}
            justifyContent="space-between"
          >
            <Box
              display="flex"
              flex="1"
              marginRight="3"
              position="relative"
              alignItems="center"
            >
              <Box
                width={{ base: "100%", md: "85%" }}
                zIndex="2"
                marginLeft={{ base: "0", md: "5%" }}
                marginTop="5%"
              >
                <Link
                  href={"/Guide/TradeGuide"}
                  textDecoration="none"
                  _hover={{ textDecoration: "underline" }}
                >
                  <Image
                    borderRadius="lg"
                    src={
                      "https://www.skyweaver.net/images/media/wallpapers/wallpaper8.jpg"
                    }
                    objectFit="contain"
                  />
                </Link>
              </Box>
              <Box zIndex="1" width="100%" position="absolute" height="100%">
                <Box
                  bgGradient={"radial(orange.600 1px, transparent 1px)"}
                  backgroundSize="20px 20px"
                  opacity="0.4"
                  height="100%"
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flex="1"
              flexDirection="column"
              justifyContent="center"
              marginTop={{ base: "3", md: "0" }}
            >
              <Heading marginTop="1">
                <Link
                  href={"/Guide/TradeGuide"}
                  textDecoration="none"
                  _hover={{ textDecoration: "underline" }}
                >
                  {t("translation:guide.index.howToTradeTitle")}
                </Link>
              </Heading>
              <Text as="p" marginTop="2" color={"white.800"} fontSize="lg">
                {t("translation:guide.index.howToTradeIntro")}
              </Text>
            </Box>
          </Box>
        </Container>
        <Container maxW={"7xl"} p="5">
          <Box
            marginTop={{ base: "1", md: "5" }}
            display="flex"
            flexDirection={{ base: "column", md: "row" }}
            justifyContent="space-between"
          >
            <Box
              display="flex"
              flex="1"
              marginRight="3"
              position="relative"
              alignItems="center"
            >
              <Box
                width={{ base: "100%", md: "85%" }}
                zIndex="2"
                marginLeft={{ base: "0", md: "5%" }}
                marginTop="5%"
              >
                <Link
                  href={"/Guide/LiquidityGuide"}
                  textDecoration="none"
                  _hover={{ textDecoration: "underline" }}
                >
                  <Image
                    borderRadius="lg"
                    src={
                      "https://www.skyweaver.net/images/media/wallpapers/wallpaper13.jpg"
                    }
                    objectFit="contain"
                  />
                </Link>
              </Box>
              <Box zIndex="1" width="100%" position="absolute" height="100%">
                <Box
                  bgGradient={"radial(orange.600 1px, transparent 1px)"}
                  backgroundSize="20px 20px"
                  opacity="0.4"
                  height="100%"
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flex="1"
              flexDirection="column"
              justifyContent="center"
              marginTop={{ base: "3", md: "0" }}
            >
              <Heading marginTop="1">
                <Link
                  href={"/Guide/LiquidityGuide"}
                  textDecoration="none"
                  _hover={{ textDecoration: "underline" }}
                >
                  {t("translation:guide.index.liquidityTitle")}
                </Link>
              </Heading>
              <Text as="p" marginTop="2" color={"white.800"} fontSize="lg">
                {t("translation:guide.index.liquidityIntro")}
              </Text>
            </Box>
          </Box>
        </Container>
        <Container maxW={"7xl"} p="5">
          <Box
            marginTop={{ base: "1", md: "5" }}
            display="flex"
            flexDirection={{ base: "column", md: "row" }}
            justifyContent="space-between"
          >
            <Box
              display="flex"
              flex="1"
              marginRight="3"
              position="relative"
              alignItems="center"
            >
              <Box
                width={{ base: "100%", md: "85%" }}
                zIndex="2"
                marginLeft={{ base: "0", md: "5%" }}
                marginTop="5%"
              >
                <Link
                  href={"/Guide/LiquidityManagementGuide"}
                  textDecoration="none"
                  _hover={{ textDecoration: "underline" }}
                >
                  <Image
                    borderRadius="lg"
                    src={
                      "https://www.skyweaver.net/images/media/wallpapers/wallpaper7.jpg"
                    }
                    objectFit="contain"
                  />
                </Link>
              </Box>
              <Box zIndex="1" width="100%" position="absolute" height="100%">
                <Box
                  bgGradient={"radial(orange.600 1px, transparent 1px)"}
                  backgroundSize="20px 20px"
                  opacity="0.4"
                  height="100%"
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flex="1"
              flexDirection="column"
              justifyContent="center"
              marginTop={{ base: "3", md: "0" }}
            >
              <Heading marginTop="1">
                <Link
                  href={"/Guide/LiquidityManagementGuide"}
                  textDecoration="none"
                  _hover={{ textDecoration: "underline" }}
                >
                  {t("translation:guide.index.howToLiquidityTitle")}
                </Link>
              </Heading>
              <Text as="p" marginTop="2" color={"white.800"} fontSize="lg">
                {t("translation:guide.index.howToLiquidityIntro")}
              </Text>
            </Box>
          </Box>
        </Container>
        <Container maxW={"7xl"} p="5">
          <Box
            marginTop={{ base: "1", md: "5" }}
            display="flex"
            flexDirection={{ base: "column", md: "row" }}
            justifyContent="space-between"
          >
            <Box
              display="flex"
              flex="1"
              marginRight="3"
              position="relative"
              alignItems="center"
            >
              <Box
                width={{ base: "100%", md: "85%" }}
                zIndex="2"
                marginLeft={{ base: "0", md: "5%" }}
                marginTop="5%"
              >
                <Link
                  href={"/Guide/ImpermanentLossGuide"}
                  textDecoration="none"
                  _hover={{ textDecoration: "underline" }}
                >
                  <Image
                    borderRadius="lg"
                    src={
                      "https://www.skyweaver.net/images/media/wallpapers/wallpaper3.jpg"
                    }
                    objectFit="contain"
                  />
                </Link>
              </Box>
              <Box zIndex="1" width="100%" position="absolute" height="100%">
                <Box
                  bgGradient={"radial(orange.600 1px, transparent 1px)"}
                  backgroundSize="20px 20px"
                  opacity="0.4"
                  height="100%"
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flex="1"
              flexDirection="column"
              justifyContent="center"
              marginTop={{ base: "3", md: "0" }}
            >
              <Heading marginTop="1">
                <Link
                  href={"/Guide/ImpermanentLossGuide"}
                  textDecoration="none"
                  _hover={{ textDecoration: "underline" }}
                >
                  {t("translation:guide.index.impermanentLossTitle")}
                </Link>
              </Heading>
              <Text as="p" marginTop="2" color={"white.800"} fontSize="lg">
                {t("translation:guide.index.impermanentLossIntro")}
              </Text>
            </Box>
          </Box>
        </Container>
      </Container>
      <Footer />
    </React.Fragment>
  );
}
