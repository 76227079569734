import {
  Flex,
  HStack,
  Image,
  Link,
  Select,
  Spacer,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Decimal from "decimal.js";
import { ethers } from "ethers";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { sksBuyPriceRate, sksSellPriceRate } from "../../../constants/exchange";
import { useGlobalState } from "../../../hooks/GlobalState";
import { EXCHANGE_ADDRESS } from "../../constant";
import { PriceTag } from "./PriceTag";
import { QuantityPicker } from "./QuantityPicker";
import { isMobile } from "react-device-detect";

export const CartItem = ({ card_id, mode, initialCount }) => {
  const { t, i18n } = useTranslation("translation");
  const [globalState, dispatch] = useGlobalState();
  const [count, setCount] = React.useState(initialCount);
  const [price, setPrice] = React.useState(-1);
  const card = globalState.shoppingCart.get(card_id)["card"];
  let max;
  switch (mode) {
    case "bulkBuy":
      max = Math.floor(card["pool_cardInPool"] - 0.01);
      break;
    case "bulkSell":
      max = card["self_cardOwned"];
      break;
    case "bulkAddLiquidity":
      max = card["self_cardOwned"];
      break;
    case "bulkRemoveLiquidity":
      max = card["pool_ownedCardInPool"];
      break;
  }

  const updatePrice = async () => {
    let cardPrice = -1;
    if (mode === "bulkBuy") {
      try {
        let cardBuyPrice =
          await globalState.exchangeTradeContract.getPrice_currencyToToken(
            [card["ID"]],
            [
              ethers.BigNumber.from(
                new Decimal(count).times(100).floor().toNumber()
              ),
            ]
          );
        cardPrice = new Decimal(cardBuyPrice[0].toNumber())
          .times(sksBuyPriceRate)
          .div(1000000)
          .toNumber();
      } catch (e) {
        cardPrice = -1;
      }
    }
    if (mode === "bulkSell") {
      let cardSellPrice =
        await globalState.exchangeTradeContract.getPrice_tokenToCurrency(
          [card["ID"]],
          [
            ethers.BigNumber.from(
              new Decimal(count).times(100).floor().toNumber()
            ),
          ]
        );
      cardPrice = new Decimal(cardSellPrice[0].toNumber())
        .times(sksSellPriceRate)
        .div(1000000)
        .toNumber();
    }
    if (mode === "bulkAddLiquidity" || mode === "bulkRemoveLiquidity") {
      let currencyReservePromise =
        globalState.exchangeTradeContract.getCurrencyReserves([card["ID"]]);
      let cardBalancePromise =
        globalState.skyweaverQueryContract.balanceOfBatch(
          [EXCHANGE_ADDRESS],
          [card["ID"]]
        );

      let [currencyReserve, cardBalance] = await Promise.all([
        currencyReservePromise,
        cardBalancePromise,
      ]);

      const cardInPool =
        cardBalance === 0
          ? new Decimal(0)
          : new Decimal(cardBalance[0].toNumber()).div(100);
      const currencyReserveInPool = new Decimal(
        currencyReserve[0].toNumber()
      ).div(1000000);
      cardPrice =
        cardBalance === 0
          ? 0
          : currencyReserveInPool.div(cardInPool).times(count).toNumber();
    }
    setPrice(cardPrice);
    if (cardPrice > 0) {
      var newCart = new Map(globalState.shoppingCart);
      const newCardItem = newCart.get(card_id);
      newCardItem["price"] = cardPrice;
      dispatch({ shoppingCart: newCart });
    }
  };

  const onValueChange = (newValue: number) => {
    setCount(newValue);
    setPrice(-1);
    var newCart = new Map(globalState.shoppingCart);
    const newCardItem = newCart.get(card_id);
    newCardItem["count"] = newValue;
    newCardItem["price"] = null;
    dispatch({ shoppingCart: newCart });
  };

  const onRemove = () => {
    var newCart = new Map(globalState.shoppingCart);
    newCart.delete(card_id);
    dispatch({ shoppingCart: newCart });
  };

  React.useEffect(() => {
    updatePrice();
  }, [count]);

  return (
    <Stack direction="row" spacing="5">
      <Image
        height={{ base: "28", md: "28" }}
        width={{ base: "24", md: "24" }}
        fit="cover"
        src={card["Image_URL"]}
        alt={card["name"]}
        draggable="false"
        loading="lazy"
      />
      <Stack width="full" spacing="3">
        <Stack
          direction={{ base: "column", md: "row" }}
          spacing="3"
          alignItems="flex-start"
        >
          <HStack spacing="0.5" width="full">
            <Text fontWeight="medium">{card["name"]}</Text>
            <Spacer />
            <Link
              display={{ base: "block", md: "none" }}
              as="button"
              type="button"
              fontWeight="medium"
              fontSize="sm"
              w="60px"
              color={"blue.500"}
              onClick={onRemove}
            >
              {t("translation:market.bulk.cart.remove")}
            </Link>
          </HStack>
          {price > 0 ? <PriceTag price={price} currency="USDC" /> : <Spinner />}
        </Stack>
        <Flex width="full" justifyContent="space-between" alignItems="center">
          <QuantityPicker
            initValue={count}
            max={max}
            onValueChange={onValueChange}
          ></QuantityPicker>
          <Link
            display={{ base: "none", md: "block" }}
            as="button"
            type="button"
            fontWeight="medium"
            fontSize="sm"
            w="60px"
            color={"blue.500"}
            onClick={onRemove}
          >
            {t("translation:market.bulk.cart.remove")}
          </Link>
        </Flex>
      </Stack>
    </Stack>
  );
};
