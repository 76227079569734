import {
  Button,
  Center,
  Flex,
  FormControl,
  FormControlProps,
  FormLabel,
  Spacer,
  Box,
  HStack,
  IconButton,
  IconButtonProps,
  Input,
  Text,
  useControllableState,
  UseControllableStateProps,
} from "@chakra-ui/react";
import Decimal from "decimal.js";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiMinus, FiPlus } from "react-icons/fi";
import { useGlobalState } from "../../../hooks/GlobalState";

interface QuantityPickerProps extends UseControllableStateProps<number> {
  max?: number;
  min?: number;
  initValue?: number;
  onValueChange?: (value: number) => void;
  width?: string;
  color?: string;
  card_id?: string;
  autoUpdate?: boolean;
  maxButtonPositon?: "bottom" | "right" | "none";
}

export const QuantityPicker = (props: QuantityPickerProps) => {
  const {
    min = 1,
    max = 50,
    initValue = 1,
    onValueChange,
    width = "180px",
    color = "black",
    card_id = "-1",
    autoUpdate = false,
    maxButtonPositon = "right",
  } = props;

  const { t, i18n } = useTranslation("translation");
  const [globalState, dispatch] = useGlobalState();
  const [value, setValue] = useState(initValue.toString());
  const setToMax = () => {
    handleValueChange(max + "");
  };
  const handleValueChange = (value) => {
    setValue(value);
    onValueChange(parseFloat(value));
  };
  const handleDecrement = () => {
    const valueNum = new Decimal(value);
    if (valueNum.isNaN()) {
    } else {
      handleValueChange(
        valueNum.plus(-1).comparedTo(min) === 1
          ? valueNum.plus(-1).toString()
          : min + ""
      );
    }
  };
  const handleIncrement = () => {
    const valueNum = new Decimal(value);
    if (valueNum.isNaN()) {
    } else {
      handleValueChange(
        valueNum.plus(1).comparedTo(max) === -1
          ? valueNum.plus(1).toString()
          : max + ""
      );
    }
  };
  useEffect(() => {
    if (autoUpdate) {
      const card = globalState.shoppingCart.get(card_id);
      if (card) {
        const newValue = card["count"];
        if (newValue.toString() !== value) {
          setValue(newValue);
        }
      } else {
        if (value !== "0") {
          setValue("0");
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState.shoppingCart]);

  const picker = (
    <Flex
      borderRadius="base"
      px="2"
      py="0.4375rem"
      borderWidth="1px"
      justifyContent="space-between"
      width={width}
    >
      <QuantityPickerButton
        onClick={handleDecrement}
        icon={<FiMinus />}
        isDisabled={parseFloat(value) === min}
        aria-label="Decrement"
      />
      <Center minW="8">
        <Input
          mx="6px"
          height={"34px"}
          textAlign={"center"}
          color={color}
          value={value}
          fontWeight="semibold"
          onChange={(e) => {
            const newValue = new Decimal(parseFloat(e.target.value));
            if (newValue.isNaN()) {
            } else {
              if (
                e.target.value.endsWith(".") ||
                e.target.value.endsWith(".0")
              ) {
                setValue(e.target.value);
              } else {
                handleValueChange(newValue.toString());
              }
            }
          }}
        ></Input>
      </Center>
      <QuantityPickerButton
        onClick={handleIncrement}
        icon={<FiPlus />}
        isDisabled={parseFloat(value) === max}
        aria-label="Increment"
      />
    </Flex>
  );

  return (
    <FormControl>
      <HStack>
        {picker}
        {maxButtonPositon === "right" ? (
          <Button isDisabled={parseFloat(value) === max} onClick={setToMax}>
            {t("translation:market.bulk.max")}
          </Button>
        ) : null}
      </HStack>
      {maxButtonPositon === "bottom" ? (
        <HStack marginTop="15px">
          <Spacer />
          <Button isDisabled={parseFloat(value) === max} onClick={setToMax}>
            {t("translation:market.bulk.max")}
          </Button>
          <Spacer />
        </HStack>
      ) : null}
    </FormControl>
  );
};

const QuantityPickerButton = (props: IconButtonProps) => (
  <IconButton
    size="sm"
    fontSize="sm"
    _focus={{ boxShadow: "none" }}
    _focusVisible={{ boxShadow: "outline" }}
    {...props}
  />
);
