import { Box, Spacer, Button, Text, Image, HStack } from "@chakra-ui/react";
import { useGlobalState } from "../hooks/GlobalState";
import Identicon from "./Identicon";
type Props = {
  handleOpenModal: any;
  account: any;
};
export default function AccountButton({ handleOpenModal, account }: Props) {
  const [globalState, dispatch] = useGlobalState();

  return (
    <Box
      display="flex"
      alignItems="center"
      background="gray.700"
      borderRadius="xl"
      py="0"
    >
      <Spacer />
      <Box px="3">
        <HStack>
          <Image src={"/icon/usdc.png"} w={5} />
          <Text color="white" fontSize="md">
            {globalState.balance}
          </Text>
        </HStack>
      </Box>
      <Spacer />
      <Button
        onClick={handleOpenModal}
        bg="gray.800"
        border="1px solid transparent"
        _hover={{
          border: "1px",
          borderStyle: "solid",
          borderColor: "blue.400",
          backgroundColor: "gray.700",
        }}
        borderRadius="xl"
        m="1px"
        px={3}
        height="38px"
        data-modal="Account"
      >
        <Text
          display={{ base: "none", md: "none", lg: "block" }}
          color="white"
          fontSize="md"
          fontWeight="medium"
          mr="2"
        >
          {account &&
            `${account.slice(0, 6)}...${account.slice(
              account.length - 4,
              account.length
            )}`}
        </Text>
        <Identicon address={account} />
      </Button>
    </Box>
  );
}
