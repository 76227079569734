import React, { ReactNode, useEffect, useState } from "react";
import {
  IconButton,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Link,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  Image,
  Container,
  Box,
  Spacer,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/react";
import {
  FiHome,
  FiBookOpen,
  FiMenu,
  FiShoppingCart,
  FiActivity,
} from "react-icons/fi";
import { IconType } from "react-icons";
import { ReactText } from "react";
import { useGlobalState } from "../hooks/GlobalState";
import AccountButton from "./AccountButton";
import ConnectButton from "./ConnectButton";
import ModalManager from "./ModalManager";
import { useTranslation } from "react-i18next";
import LanguageModal from "./LanguageModal";

interface LinkItemProps {
  name: string;
  icon: IconType;
  path: string;
}

export default function SimpleSidebar({ children }: { children: ReactNode }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showAlert, setShowAlert] = useState(false);

  return (
    <Box minH="100vh" bg={"black"}>
      <Alert
        display={showAlert ? "flex" : "none"}
        status="error"
        position="absolute"
        zIndex={4}
      >
        <AlertIcon />
        <AlertTitle mr={2}>
          <Link href="https://forum.polygon.technology/t/alert-upcoming-downtime-due-to-issue-with-pos-chain/2324">
            Polygon network is under maintainance.
          </Link>
        </AlertTitle>
        <AlertDescription>
          Trade and liquidity actions won't work during the maintainance time.
        </AlertDescription>
        <CloseButton
          onClick={() => setShowAlert(false)}
          position="absolute"
          right="8px"
          top="8px"
        />
      </Alert>
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav display={{ base: "flex", lg: "none" }} onOpen={onOpen} />
      <Box
        ml={{ base: 0, lg: 0 }}
        paddingTop={{ base: "0px", lg: "100px" }}
        bg="blackAlpha.900"
      >
        {children}
      </Box>
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const {
    isOpen: isLanguageOpen,
    onOpen: onLanguageOpen,
    onClose: onLanguageClose,
  } = useDisclosure();
  const { t, i18n } = useTranslation("translation");
  const LinkItems: Array<LinkItemProps> = [
    { name: t("common.homepageLabel"), icon: FiHome, path: "/" },
    { name: t("common.guideLabel"), icon: FiBookOpen, path: "/Guide" },
    // { name: 'Asset', icon: FiGrid, path: "/Asset" },
    // { name: 'Liquidity', icon: FiOctagon, path: "/Liquidity" },
    { name: t("common.marketLabel"), icon: FiShoppingCart, path: "/Market" },
    { name: t("common.dataLabel"), icon: FiActivity, path: "/Data" },
  ];
  return (
    <Box
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={"gray.200"}
      w={{ base: "full", lg: 60 }}
      pos="fixed"
      h="full"
      zIndex={4}
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Image src={"/public/img/logo192.png"}></Image>
        <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
          SkyweaverSwap
        </Text>
        <CloseButton display={{ base: "flex", lg: "none" }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => (
        <NavItem key={link.name} icon={link.icon} path={link.path}>
          {link.name}
        </NavItem>
      ))}
      <IconButton
        onClick={onLanguageOpen}
        aria-label={"translation"}
        position={"absolute"}
        bottom={"100px"}
        left={"10"}
        backgroundColor={"transparent"}
        icon={<Image width={"10"} src="/icon/translation.png" />}
      ></IconButton>
      <LanguageModal isOpen={isLanguageOpen} onClose={onLanguageClose} />
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: ReactText;
  path: string;
}
const NavItem = ({ icon, children, path, ...rest }: NavItemProps) => {
  return (
    <Link
      href={path}
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: "cyan.400",
          color: "white",
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  const [modalOpen, setModal] = useState("");
  const [globalState, dispatch] = useGlobalState();

  const openModal = (event) => {
    event.preventDefault();
    let element = event.target;
    while (element != null) {
      if (element.dataset.modal) {
        setModal(element.dataset.modal);
        return;
      }
      element = element.parentElement;
    }
  };

  const closeModal = () => {
    setModal("");
  };

  const walletButton = globalState.account ? (
    <Container maxW="240px" align="right" mx={0}>
      <AccountButton
        handleOpenModal={openModal}
        account={globalState.account}
      />
      <ModalManager onClose={closeModal} modal={modalOpen} />
    </Container>
  ) : (
    <Box marginLeft={"20px"}>
      <ConnectButton showOther={false} />
      <ModalManager onClose={closeModal} modal={modalOpen} />
    </Box>
  );
  return (
    <Flex
      bg={"gray.900"}
      ml={{ base: 0, lg: 60 }}
      px={{ base: 4, lg: 24 }}
      height="20"
      alignItems="center"
      borderBottomWidth="1px"
      borderBottomColor={"gray.700"}
      justifyContent="flex-start"
      {...rest}
    >
      <IconButton
        variant="outline"
        onClick={onOpen}
        aria-label="open menu"
        icon={<FiMenu color="white" />}
      />
      <Spacer />
      {walletButton}
    </Flex>
  );
};
