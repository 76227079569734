import {
  Container,
  Stack,
  Box,
  Heading,
  Text,
  AspectRatio,
  Image,
  Center,
  Link,
} from "@chakra-ui/react";
import React from "react";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";

export default function LiquidityGuide() {
  const { t, i18n } = useTranslation("translation");
  ReactGA.pageview(window.location.pathname);

  if (i18n.resolvedLanguage == "zh") {
    return (
      <React.Fragment>
        <Container maxW={"3xl"} textColor={"white"}>
          <Stack
            as={Box}
            textAlign={"center"}
            spacing={{ base: 8, md: 10 }}
            py={{ base: 20, md: 36 }}
          >
            <Heading fontWeight={100} fontSize={"4xl"} lineHeight={"110%"}>
              <b>{t("translation:guide.index.liquidityTitle")}</b>
              <br />
            </Heading>
            <Text fontWeight={100} fontSize={"2xl"} textAlign={"left"}>
              <b>什么是流动性</b>
            </Text>
            <Text margin-top={"0"} color={"black.500"} textAlign={"left"}>
              Skyweaver
              使用了一个去中心化的市场（Niftyswap），您可以在其中买卖卡牌。
              它允许您立即购买/出售卡片，而无需等待其他玩家与您交易。
              每张卡都有一个流动池，当您进行交易时，您实际是在与流动池进行交易。
              如果您想了解更多信息，请查看官方市场概览
              <Link
                textDecoration={"underline"}
                href="https://www.skyweaver.net/news/market-overview"
              >
                官方市场概览
              </Link>
              。
              <br />
              <br />
              <Center>
                <AspectRatio ratio={1.8} w={"800px"} textAlign={"center"}>
                  <Image
                    src={
                      "https://skyweaver.ghost.io/content/images/size/w2400/2021/11/How-the-sw-market-works.png"
                    }
                    alt="Card"
                  />
                </AspectRatio>
              </Center>
              <br />
              <br />
              <Text fontWeight={100} fontSize={"2xl"} textAlign={"left"}>
                <b>什么决定了卡牌的价格？</b>
              </Text>
              <br />
              <br />
              有人可能会认为，既然他可以与其他人交换卡牌，那么他应该能够为每张卡牌设置一个价格。
              然而去中心化市场的运作方式和传统中心化市场不同。
              价格仍然由供需决定，但您在市场上看到的价格有其自己的公式。
              <br />
              <br />
              每个流动资金池中都有两个东西：卡牌和 USDC。 最初，Skyweaver
              开发商（Horizon）为每张卡提供了一个流动池，包含 25 张卡牌和 250
              USDC。 按照这个比例，池中的每张卡的价值为 10 USDC。
              为简单起见，假设现在每笔交易不涉及任何费用，并且价格不会随着您购买/出售手卡的方式而改变。
              <br />
              <br />
              <b>（卡牌数量）*（每张卡牌的价格）= 池中的USDC数量</b>
              <br />
              <br />
              在我们的示例中，池中的 USDC = 250。卡牌数量 = 25。每张卡的价格 =
              10。
              <br />
              现在，如果你想从池中购买一张卡，你将 10 USDC
              添加到池中并从池中取出 1 张卡。 新卡价格为
              <br />
              <br />
              <b>
                每张卡的价格 = 池中的 USDC / 池中卡牌数量 = 260 / 24 = 10.83
              </b>
              <br />
              <br />
              同样，如果你向池中出售一张卡牌，你从池中取出 10 USDC 并添加 1
              张卡到池中。
              <br />
              <br />
              <b>每张卡的价格 = 池中的 USDC / 池中卡牌数量 = 240 / 26 = 9.23</b>
              <br />
              <br />
              确切的公式更为复杂，因为价格会随着您购买/出售的卡牌数量而变化。
              <br />
              <br />
              <Text fontWeight={100} fontSize={"2xl"} textAlign={"left"}>
                <b>我为什么要提供流动性？</b>
              </Text>
            </Text>
            <Text color={"black.500"} textAlign={"left"}>
              你可能会问，我为什么要提供流动性？
              在我们上面展示的示例中，我们假设不涉及任何费用。
              但实际上，Skyweaver 开发商（Horizon）对每笔交易收取约 7%
              的费用，其中 1% 进入该卡的 USDC 池。
              让我们看另一个例子，为了更容易理解，我们使用近似的卖出/买入价格来向您展示手续费是如何运作的：
              <br />
              <br />
              一张卡的流动资金池有 10 张卡， 100 USDC。有人以 10 USDC 的价格购买
              1 张卡，池中现在有 9 张卡和 110.10 USDC（价格+10，费用+0.10）。
              然后另外一人又以 10 USDC 的价格卖出一张卡，池中现在有 10 张卡和
              100.20 USDC（价格为-10，费用为 +0.10）
              <br />
              <br />
              池中的卡牌数量和开始时一样，但是池中的 USDC 增加了！ 如果我拥有
              50% 的池子的所有权，那我就可以赚取一半的收益！
              交易越多，我赚的就越多！ 这里需要注意的一点是，收益仍在池中。
              您需要赎回一些流动性才能取回收益。
              <br />
              <br />
              假设您将 10 张卡，100 USDC 添加到有 10 张卡，100 USDC 的流动池中。
              你拥有池子的 50% 所有权，池子现在有 20 张卡，200 USDC。
              当您想赎回时时，可能会发生4种情况：
              <br />
              <br />
              1) 市场不景气，卖卡的人比买卡的多，池子里有 40 张卡，120 美元。
              如果您现在赎回流动性，您将获得 20 张卡和 60 USDC。
              <br />
              2) 市场非常火热，买卡的为人比卖卡的多，池子内现在有 10 张卡和 500
              USDC。 如果您现在赎回流动性，您将获得 5 张卡和 250 USDC。
              <br />
              3）如果交易数量比较平稳，价格没有太大波动，那么一段时间后池中可能有
              20 张卡和 300 USDC，您赎回的将是 10 张卡和 150 USDC。
              <br />
              4）如果没有人交易，你的份额不会改变，此时赎回可以拿回 10 张卡和
              100 USDC。
              在这种情况下没有收益没有损失。（忽略数据精度问题，详情见
              <Link textDecoration={"underline"} href="/Guide/FAQ">
                FAQ
              </Link>{" "}
              ）
              <br />
              <br />
              如您所见，您拥有的卡或您拥有的 USDC
              都会增加，也许两者都会增加，但不会发生两者都下降的情况。
              <br />
              <br />
              另一篇文章
              <Link
                textDecoration={"underline"}
                href="/Guide/LiquidityManagementGuide"
              >
                如何提供流动性?
              </Link>{" "}
              提供了更多信息以帮助你在SkyweaverSwap管理你的流动池。
            </Text>
            <Text fontWeight={100} fontSize={"2xl"} textAlign={"left"}>
              <b>提供流动性有风险吗？</b>
            </Text>
            <Text margin-top={"0"} color={"black.500"} textAlign={"left"}>
              是的，有风险。一切毫无风险的收益都是骗局。
              <br />
              <br />
              像所有去中心化市场一样，您可能会承受无常损失(Impermanent
              Loss)。我根据自己的理解写了一篇指南：
              <Link
                textDecoration={"underline"}
                href="/Guide/ImpermanentLossGuide"
              >
                {t("translation:guide.index.impermanentLossTitle")}
              </Link>
              。<br></br>
              <br></br>
              您也可以在网上搜索“无常损失(Impermanent Loss)”以获取更多信息。
            </Text>
          </Stack>
        </Container>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <Container maxW={"3xl"} textColor={"white"}>
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 36 }}
        >
          <Heading fontWeight={100} fontSize={"4xl"} lineHeight={"110%"}>
            <b>Liquidity in Skyweaver marketplace</b>
            <br />
          </Heading>
          <Text fontWeight={100} fontSize={"2xl"} textAlign={"left"}>
            <b>What is liquidity?</b>
          </Text>
          <Text margin-top={"0"} color={"black.500"} textAlign={"left"}>
            Skyweaver has a decentralized marketplace(Niftyswap) where you can
            buy and sell cards. It allows you to buy/sell cards instantly,
            without having to wait for another player to trade with you. The
            reason you can do this is because each card has a liquidity pool,
            you are trading with the liquidity pool when you do a trade. If you
            want to know more about it, take a look at{" "}
            <Link
              textDecoration={"underline"}
              href="https://www.skyweaver.net/news/market-overview"
            >
              the official market overview
            </Link>
            .
            <br />
            <br />
            <Center>
              <AspectRatio ratio={1.8} w={"800px"} textAlign={"center"}>
                <Image
                  src={
                    "https://skyweaver.ghost.io/content/images/size/w2400/2021/11/How-the-sw-market-works.png"
                  }
                  alt="Card"
                />
              </AspectRatio>
            </Center>
            <br />
            <br />
            <Text fontWeight={100} fontSize={"2xl"} textAlign={"left"}>
              <b>What decides the card price?</b>
            </Text>
            <br />
            <br />
            One may think that since he can trade card with other people, he
            should be able to set a price for each card. Though that's not the
            case with a decentralized marketplace. The price is still decided by
            supply and demand, but the exact price you see in the marketplace
            has its own forluma.
            <br />
            <br />
            Every liquidity pool has two things in it: Cards and USDC.
            Initially, Skyweaver team(Horizon) provided a liquidity pool for
            each card initially, with 25 cards and 250 USDC. With this ratio,
            each card in the pool now worth 10 USDC. To make things simple, let
            assume there's no fee involved in each trade for now, and price does
            not change with how many cards you buy/sell.
            <br />
            <br />
            <b>(Number of cards) * (USDC per card) = USDC in pool</b>
            <br />
            <br />
            In our example, USDC in pool = 250. Number of cards = 25. USDC per
            card = 10.
            <br />
            Now if you want to buy a card from the pool, you add 10 USDC to the
            pool and take 1 card from the pool. The new card price will be
            <br />
            <br />
            <b>
              USDC per card = USDC in pool / Number of cards = 260 / 24 = 10.83
            </b>
            <br />
            <br />
            Similarly, if you sell a card to the pool, you take 10 USDC from the
            pool and add 1 card to the pool.
            <br />
            <br />
            <b>
              USDC per card = USDC in pool / Number of cards = 240 / 26 = 9.23
            </b>
            <br />
            <br />
            The exact formula is a bit more complicated, as the price will
            change with the number of cards you buy/sell, but you get the idea
            here.
            <br />
            <br />
            <Text fontWeight={100} fontSize={"2xl"} textAlign={"left"}>
              <b>Why should I provide Liquidity?</b>
            </Text>
          </Text>
          <Text color={"black.500"} textAlign={"left"}>
            You may ask, why should I provide liquidity? In the example we show
            above, we assume there's no fees involved. In the actual world,
            Skyweaver team(Horizon) charges a ~7% fee for each trade, and 1% of
            that goes into the USDC pool for that card. Let's see another
            example, for easier understanding we are using an estimate sell/buy
            price to just show you the idea:
            <br />
            <br />A card's liquidity pool has <b>10 cards, 100 USDC</b> in it.
            Someone buys 1 card at 10 USDC, the pool now has{" "}
            <b>
              9 cards and 110.10 USDC(+10 for the price and +0.10 for the fee)
            </b>
            . Then someone else sells a card at 10 USDC, the pool now has{" "}
            <b>
              10 cards and 100.20 USDC(-10 for the price and +0.10 for the fee)
            </b>
            <br />
            <br />
            Now you can see the cards in pool is the same as the beginning, but
            the USDC in pool increased! If I own 50% of the pool, I can earn
            half of that increase from the pool! And the more trades people
            make, the more I will earn! One thing here to note is, the earn is
            still in the pool itself. You will need to withadraw some potion of
            your liquidity to get back the gain.
            <br />
            <br />
            Let's say you add 10 cards, 100 USDC to a pool with 10 cards, 100
            USDC. Now you own 50% of the pool, the pool now has 20 cards, 200
            USDC. There are 4 cases that may happen when you want to withdraw:
            <br />
            <br />
            1) After a while the pool has 40 cards, 120 USDC, because people are
            selling more. If you withdraw your liuquidity now, you get 20 cards
            and 60 USDC.
            <br />
            2) After a while it has 10 cards and 500 USDC, because people are
            buying more. Now you withdraw with 5 cards and 250 USDC.
            <br />
            3) If trades are even, then it may has 20 cards and 300 USDC in the
            pool after a while, and you withdraw will be 10 cards and 150 USDC.
            <br />
            4) If no one trades, your share will not change and you withdraw
            with 10 cards and 100 USDC.
            <br />
            <br />
            As you can see, either cards you own or USDC you own will increase,
            maybe both, but it won't happen that both are down.
            <br />
            <br />
            Check out the guide{" "}
            <Link
              textDecoration={"underline"}
              href="/Guide/LiquidityManagementGuide"
            >
              How can I provide liquidity?
            </Link>{" "}
            for how to do it yourself.
          </Text>
          <Text fontWeight={100} fontSize={"2xl"} textAlign={"left"}>
            <b>Are there risks in providing liquidity?</b>
          </Text>
          <Text margin-top={"0"} color={"black.500"} textAlign={"left"}>
            Yes there are risks. If someone tells you that you can earn money
            without any risk, they are lying to you.
            <br />
            <br />
            Like all decentralized marketplace, you may lose money because of
            impermanent loss. I wrote a guide{" "}
            <Link
              textDecoration={"underline"}
              href="/Guide/ImpermanentLossGuide"
            >
              Impermanent Loss in Skyweaver
            </Link>{" "}
            with my own understanding. You can also find a lot of useful
            informations online.
          </Text>
        </Stack>
      </Container>
    </React.Fragment>
  );
}
