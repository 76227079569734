import {
  Container,
  Stack,
  Box,
  Heading,
  Text,
  AspectRatio,
  Image,
  Center,
  Link,
} from "@chakra-ui/react";
import React from "react";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";

export default function TradeGuide() {
  const { t, i18n } = useTranslation("translation");
  ReactGA.pageview(window.location.pathname);

  if (i18n.resolvedLanguage === "zh") {
    return (
      <React.Fragment>
        <Container maxW={"3xl"} textColor={"white"}>
          <Stack
            as={Box}
            textAlign={"center"}
            spacing={{ base: 8, md: 14 }}
            py={{ base: 20, md: 36 }}
          >
            <Heading fontWeight={100} fontSize={"4xl"} lineHeight={"110%"}>
              <b>{t("translation:guide.index.howToTradeTitle")}</b>
              <br />
            </Heading>
            <Text color={"black.500"} textAlign={"left"}>
              你可能会问为什么我应该在 SkyweaverSwap 上交易而不是在游戏中交易？
              <b> 原因之一是您在 SkyweaverSwap 上交易时会获得更好的价格！</b>
              <p></p>
              <br />
              <b>SkyweaverSwap 收取的手续费比游戏内市场低 0.5%</b>
              <br />
              <br />
              但是为什么网站上显示的差价大于0.5%呢？ 答案有点复杂。
              <Link textDecoration={"underline"} href="/Guide/FAQ">
                常见问题解答FAQ
              </Link>
              中有显示价格的具体算法。
              <br />
              <br />
              您使用与游戏内市场完全相同的流动池进行交易，SkyweaverSwap
              更便宜，因为它免除了您需要在游戏内市场支付的一些前端费用，并设置了更小的滑点。
              当您在 SkyweaverSwap
              上交易时，您会看到游戏市场中的卡牌库存发生相应的变化。
              <br />
              只需进入市场页面，点击您要交易的卡片上的<b>交易</b>
              按钮即可进行交易。
              <br />
              <br />
              <Center>
                <AspectRatio ratio={0.6} w={"400px"} textAlign={"center"}>
                  <Image src={"/img/zh/asset-card.png"} alt="Card" />
                </AspectRatio>
              </Center>
            </Text>
            <Text color={"black.500"} textAlign={"left"}>
              点击之后您应该会看到如下所示的详细信息页面。
              输入您要购买/出售的数量，单击“买入/卖出”按钮。
              然后前往您的游戏内钱包并确认交易。
              因为每笔交易都需要您在钱包中确认，网站无法从从您的钱包中窃取资产。
              <br />
              <br />
              <Center>
                <AspectRatio ratio={1.65} w={"500px"} textAlign={"center"}>
                  <Image src={"/img/zh/asset-detail.png"} alt="Card" />
                </AspectRatio>
              </Center>
              <br />
              <br />
              SkyweaverSwap与游戏内市场的一个区别是您可以购买/出售低至 0.01
              张卡牌。 为什么需要0.01？ 查看
              <Link
                textDecoration={"underline"}
                href="/Guide/LiquidityManagementGuide"
              >
                {t("translation:guide.index.howToLiquidityTitle")}
              </Link>{" "}
              指南了解更多详情。
            </Text>
          </Stack>
        </Container>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <Container maxW={"3xl"} textColor={"white"}>
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 8, md: 14 }}
          py={{ base: 20, md: 36 }}
        >
          <Heading fontWeight={100} fontSize={"4xl"} lineHeight={"110%"}>
            <b>How to trade on SkyweaverSwap</b>
            <br />
          </Heading>
          <Text color={"black.500"} textAlign={"left"}>
            You may ask why I should trade on SkyweaverSwap and not the in the
            game?
            <b>
              {" "}
              One reason is that you will get a better price when you trade on
              SkyweaverSwap!
            </b>
            <p></p>
            <br />
            SkyweaverSwap charges 0.5% less fee than the in-game market.
            <br />
            <br />
            But why the price difference on the website is bigger than 0.5%? The
            answer is a bit complicated. Check our{" "}
            <Link textDecoration={"underline"} href="/Guide/FAQ">
              FAQ
            </Link>{" "}
            for the details.
            <br />
            <br />
            You are trading with the exact same pool as in-game market,
            SkyweaverSwap is cheaper because it gets rid of some of the frontend
            fees you need to pay in the in-game market, and set a smaller
            slippage. You will see card stocks change in the game market as you
            trade on SkyweaverSwap.
            <br />
            To trade, simply go to the Market page, click on the <b>
              Trade
            </b>{" "}
            button on the card you want to trade.
            <br />
            <br />
            <Center>
              <AspectRatio ratio={0.88} w={"400px"} textAlign={"center"}>
                <Image src={"/img/asset-card.png"} alt="Card" />
              </AspectRatio>
            </Center>
          </Text>
          <Text color={"black.500"} textAlign={"left"}>
            Now you should see a detail page like below. Enter the amount you
            want to buy/sell and click the <b>Buy/Sell</b> button. Then go to
            your in-game wallet and confirm the trade. Because every transaction
            needs your confirmation in the wallet, there's no risk the website
            will hack things from your wallet. You always have full control of
            your assets.
            <br />
            <br />
            <Center>
              <AspectRatio ratio={1.25} w={"500px"} textAlign={"center"}>
                <Image src={"/img/asset-detail.png"} alt="Card" />
              </AspectRatio>
            </Center>
            <br />
            <br />
            One thing that is different than the in-game market is you can
            buy/sell as little as 0.01 card. Why is this needed? Checkout the{" "}
            <Link
              textDecoration={"underline"}
              href="/Guide/LiquidityManagementGuide"
            >
              How can I provide liquidity?
            </Link>{" "}
            guide for more details.
          </Text>
        </Stack>
      </Container>
    </React.Fragment>
  );
}
